import React, { PureComponent } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import PropTypes from 'prop-types';
import { getItemFromStorage } from 'services/storage';
import { isMasquerading } from 'utils/common';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from "react-router-dom";
import SelectPatient from '../SelectPatient';

import './switchPatient.scss';
import { PrimaryModal } from 'components/StyleGuideComponents/common/modals';

class SwitchPatient extends PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isMasqueradingSuccessful) {
      this.setState({
        showModal: false,
      });
    }
  }

  onModalClose = () => {
    this.setState({
      showModal: false,
    });
  }

  onSwitchPatientClicked = () => {
    this.setState({
      showModal: true,
    });
  }

  renderSwitchPatientView = () => (
    <div>
      <span className="primary-text-color icon icon-font-a-switch-patient" onClick={this.onSwitchPatientClicked} />
      {
        this.state.showModal && (
          <PrimaryModal
            show={this.state.showModal}
            onHide={this.onModalClose}
            bodyHeader={{
              text: "Switch Patient"
            }}
            bodyContent={{
              text: <div className="switch-patient-modal">
                <SelectPatient isShownModaly />
              </div>
            }}
          />
        )}
    </div>
  )

  render() {
    const proxyUser = isEmpty(getItemFromStorage('proxyUser')) ? null : JSON.parse(getItemFromStorage('proxyUser'));
    return (
      isMasquerading() && proxyUser ?
        this.renderSwitchPatientView()
        : null
    );
  }
}

SwitchPatient.propTypes = {
  isMasqueradingSuccessful: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  isMasqueradingSuccessful: state.caregiver ? state.caregiver.isMasqueradingSuccessful : false,
});

const mapDispatchToProps = () => ({
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect,
  withRouter
)(SwitchPatient);
