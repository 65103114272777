import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import { withRouter } from 'react-router-dom';
import injectReducer from "utils/injectReducer";
import LightboxModal from "components/LightboxModal";
import { Modal } from "react-bootstrap/lib";
import { SingleSelectWithoutBorder as CustomizedSelect, CustomMultiSelect } from "components/SelectV2";
import HelpBlock from "components/HelpBlock";
import validationConfig from "./validator";
import validate, { clearErrorsForField } from "common/validator";
import {
  onfetchAllLocationsOfTheDepartmentRequest,
  onAddNewLocationRequest,
  updateLocationAction,
  onfetchTimezonesRequest,
} from "./actions";
import { getDepartmentProceduresRequest } from 'common/actions';
import { getDepartmentProceduresReducer } from 'common/reducer';
import { locationAdministrationReducer } from "./reducer";
import Pagination from 'components/Pagination';
import { getItemFromStorage } from 'services/storage';
import Tab from 'react-bootstrap/lib/Tab';
import Nav from 'react-bootstrap/lib/Nav';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import NavItem from 'react-bootstrap/lib/NavItem';
import Emptyview from 'components/Emptyview';
import NoProviders from '../../assets/images/default_provider.png';
import { PrimaryButton, SecondaryButton } from "components/StyleGuideComponents/common/buttons";
import { PrimaryModal } from "components/StyleGuideComponents/common/modals";

class LocationAdministration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddEditLocationModal: false,
      name: "",
      title: "",
      latitude: "",
      longitude: "",
      timezone: "",
      errors: {},
      procedures: [],
      disableTimezone: false,
      page: 1,
      canAddOnlyProviders: false,
      filterName: "",
      previousFilterData: {
        previousPage: 1,
        previousFilterName: "",
      }
    };
  }

  componentDidMount = () => {
    const currentUser = JSON.parse(getItemFromStorage('currentUser'));
    if (!(currentUser.hasSuperUserPrivileges && currentUser.isAdmin)) {
      this.props.history.push('/not-found');
    }
    else {
      window.addEventListener("scroll", this.scrollEvent);
      this.props.fetchAllLocationsOfTheDepartment({ 'page': this.state.page });
      this.props.fetchTimezones();
      this.props.getDepartmentProceduresRequest();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isRequestInProgress === false &&
      prevProps.isRequestInProgress === true
    ) {
      this.setState({
        showAddEditLocationModal: this.props.show,
      });
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.procedures !== nextProps.procedures) {
      this.setState({ procedures: nextProps.procedures })
    }
    if (this.state.canAddOnlyProviders !== nextProps.canAddOnlyProviders) {
      this.setState({
        canAddOnlyProviders: nextProps.canAddOnlyProviders,
      });
    }
  }

  scrollEvent = () => {
    let tableHeader = document.getElementById("main-header");
    let fixedHeader = document.getElementById("fixed-header");

    if (tableHeader != null && fixedHeader != null) {
      tableHeader = document.getElementById("main-header").getBoundingClientRect();
      fixedHeader = document.getElementById("fixed-header").getBoundingClientRect();

      if (tableHeader.top <= fixedHeader.top) {
        document.getElementById("fixed-header").style.visibility = 'visible';
      }
      else {
        document.getElementById("fixed-header").style.visibility = 'hidden';
      }
    }
  }

  onAddEditLocationModal = (location) => {
    const {
      id,
      name,
      title,
      latitude,
      longitude,
      timezone,
    } = location || {};

    const selectedProcedures = this.getSelectedProcedures(id)

    this.setState({
      locationId: id ? id : null,
      name: name ? name : "",
      title: title ? title : "",
      latitude: latitude ? latitude : "",
      longitude: longitude ? longitude : "",
      timezone: timezone ? { label: `${timezone}`, value: `${timezone}` } : "",
      showAddEditLocationModal: true,
      procedures: selectedProcedures ? selectedProcedures : [],
      disableTimezone: timezone ? true : false,
    });
  };

  getSelectedProcedures = (id) => {
    const selectedLocation = this.props.locations.find((item) => item.id === id);

    const selectedItems = selectedLocation && selectedLocation.procedures.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    return selectedItems;
  }

  onAddNewUser = () => {
    this.props.history.push('/users');
  }

  handlePagination = (page) => {
    this.setState({ page })
    this.props.fetchAllLocationsOfTheDepartment({ 'page': page });
  }

  hideAddEditLocationModal = () => {
    this.setState({
      showAddEditLocationModal: false,
      errors: {},
    });
  };

  changeFieldHandler = (e) => {
    const obj = {};
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, e.target.name);
    this.setState(obj);
  };

  selectTimezone = (selectedOption) => {
    this.setState({
      timezone: selectedOption,
      errors: clearErrorsForField(this.state.errors, "timezone"),
    });
  }

  selectProcedures = (selectedValues) => {
    this.setState({ procedures: selectedValues, errors: clearErrorsForField(this.state.errors, "procedures"), });
  }

  onAddLocation = () => {
    this.validateAddEditLocationFormValues();
  };

  onEditLocation = () => {
    this.validateAddEditLocationFormValues();
  };



  validateAddEditLocationFormValues = () => {
    validate(
      validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  };

  onFormValidationFailure = (errors) => {
    this.setState({ errors });
  };

  onFormValidationSuccess = () => {
    const params = {
      name: this.state.name,
      title: this.state.title,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      timezone: this.state.timezone.value,
      pagination: { page: this.state.page }
    };

    if (this.state.procedures) {
      params.procedures_ids = this.state.procedures.map((item) => item.value)
    }

    if (this.state.locationId) {
      params.id = this.state.locationId;

      this.props.updateLocation(params);
    } else {
      this.props.addLocation(params);
    }
  };

  isFilterUpdated = (currentData, previousData) => {
    return (previousData.previousPage !== currentData.page) ||
      (currentData.filterName.trim().toUpperCase() !== previousData.previousFilterName.trim().toUpperCase())
  }

  changeFilterName = (e) => {
    this.setState({ filterName: e.target.value });
  }

  onSetFilter = (isPagination) => {
    const { filterName } = this.state;

    const page = isPagination ? this.state.page : 1;

    let currentData = { filterName, 'page': this.state.page }
    if (this.isFilterUpdated(currentData, this.state.previousFilterData)) {
      this.props.fetchAllLocationsOfTheDepartment({ 'page': page, name: filterName });
      this.setState({
        page: page,
        previousFilterData: {
          previousPage: this.state.page,
          previousFilterName: this.state.filterName,
        }
      });
    }
  }

  onResetFilter = () => {
    this.setState({
      filterName: '',
      page: 1,
      errors: {},
      previousFilterData: {
        previousPage: 1,
        previousFilterName: '',
      }
    }, () => this.props.fetchAllLocationsOfTheDepartment({ 'page': this.state.page }));
  }

  renderFilters = () => {
    return (
      <div className="filters-container">
        <div className="filters-label">
          Filters
        </div>
        <div className="row filter-wrapper">

          <div className="col-xs-12">
            <label htmlFor="filterName">Facility Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Type Name here"
              onChange={this.changeFilterName}
              value={this.state.filterName}
              name="filterName"
            />
          </div>
          <div className="col-xs-12 btn-wrapper">
            <SecondaryButton
              className={`clear-all ${this.state.isFilterApplied ? '' : 'cursor-pointer'}`}
              onClick={this.onResetFilter}
            >
              Clear All
            </SecondaryButton>
            <SecondaryButton className="apply-button"
              onClick={() => this.onSetFilter(false)}
            >
              Apply Filters
            </SecondaryButton>
          </div>
        </div>
      </div>
    );
  }

  renderData = () => {
    return (
      <>
        {this.props.locations.length > 0 ?
          <div className="row">
            <div className="col-sm-12">
              <div className="ui-table-container">
                <table className="ui-table">
                  <thead className="sticky-header">
                    <tr className=''>
                      <th>Facility Name</th>
                      <th>Address</th>
                      <th>Timezone</th>
                      <th>Procedures</th>
                      <th></th>
                      <th style={{ width: '50px' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.locations.map((location) => (
                      <tr className='' key={location.id}>
                        <td>{location.name}</td>
                        <td>{location.title}</td>
                        <td>{location.timezone}</td>
                        <td>
                          {
                            location.procedures.length > 0 ? location.procedures.map((proc) => (
                              <p key={proc.id}>{proc.name}</p>
                            )) : '- -'
                          }
                        </td>
                        <td>
                          <span
                            className="icon icon-font-a-write-message cursor-pointer"
                            onClick={() =>
                              this.onAddEditLocationModal(location)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div style={{ marginBottom: '20px', marginTop: '10px' }}>
                {this.props.pagination &&
                  <Pagination pagination={this.props.pagination} title="Providers" handlePagination={this.handlePagination} />
                }
              </div>
            </div>
          </div>
          :
          <Emptyview imgSrc={NoProviders} className="" message="No Users" />
        }
      </>
    )
  }

  renderChildComponents = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-3 col-md-3 col-lg-3 filter-container-wrapper" style={{ position: 'fixed' }}>
            {this.renderFilters()}
          </div>
          <div className="col-sm-9 col-md-9 col-lg-9 record-list-wrapper" style={{ float: 'right' }}>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <span className="add-new-link" onClick={this.onAddEditLocationModal}>
                <span className="icon icon-font-a-add-new-task"></span>
                Add New Location
              </span>
              <span className="pagination">
                {this.props.pagination &&
                  <Pagination pagination={this.props.pagination} title="Providers" handlePagination={this.handlePagination} />
                }
              </span>
            </div>
            <div className="clr"></div>
            {this.renderData()}
          </div>
        </div>
      </React.Fragment>)
  }


  render() {
    return (
      <>
        {this.props.manageLocationsEnabled &&
          <div className="col-xs-12 content-administration">
            <Tab.Container id="admin-activity-tabs">
              <React.Fragment>
                <div className='admin-navigation-div'>
                  <div className="admin-navigation-section">
                    <Nav bsStyle="pills" justified>
                      <LinkContainer to="/users" replace>
                        <NavItem eventKey="providers">
                          <React.Fragment>
                            <i className="icon icon-font-a-patient-under-physician"></i>
                            <span className="icon-label">{this.state.canAddOnlyProviders ? 'Providers' : 'Users'}</span>
                          </React.Fragment>
                        </NavItem>
                      </LinkContainer>
                      <LinkContainer to="/locations" replace>
                        <NavItem eventKey="locations">
                          <React.Fragment>
                            <i className="icon icon-font-a-location"></i>
                            <span className="icon-label">Locations</span>
                          </React.Fragment>
                        </NavItem>
                      </LinkContainer>
                    </Nav>
                  </div>
                </div>
                <div className="tab-content-wrapper">
                  <Tab.Content>
                    {
                      this.renderChildComponents()
                    }
                  </Tab.Content>
                </div>
              </React.Fragment>
            </Tab.Container>
            <PrimaryModal
              show={this.state.showAddEditLocationModal}
              onHide={this.hideAddEditLocationModal}
              bodyHeader={{
                text: this.state.locationId ? "Edit Location" : "Add Location"
              }}
              bodyContent={{
                text: <div id="add-location-modal">
                  <div
                    className={`form-group ${this.state.errors.name !== undefined ? "has-error" : ""
                      }`}
                  >
                    <label htmlFor="">Facility Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={this.state.name}
                      onChange={this.changeFieldHandler}
                    />
                    <HelpBlock value={this.state.errors.name} />
                  </div>

                  <div
                    className={`form-group ${this.state.errors.title !== undefined ? "has-error" : ""
                      }`}
                  >
                    <label htmlFor="">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={this.state.title}
                      onChange={this.changeFieldHandler}
                    />
                    <HelpBlock value={this.state.errors.title} />
                  </div>

                  <div
                    className={`form-group ${this.state.errors.latitude !== undefined ? "has-error" : ""
                      }`}
                  >
                    <label htmlFor="">Latitude</label>
                    <input
                      type="number"
                      className="form-control no-spinner"
                      name="latitude"
                      value={this.state.latitude}
                      onChange={this.changeFieldHandler}
                    />
                    <HelpBlock value={this.state.errors.latitude} />
                  </div>

                  <div
                    className={`form-group ${this.state.errors.longitude !== undefined ? "has-error" : ""
                      }`}
                  >
                    <label htmlFor="">Longitude</label>
                    <input
                      type="number"
                      className="form-control no-spinner"
                      name="longitude"
                      value={this.state.longitude}
                      onChange={this.changeFieldHandler}
                    />
                    <HelpBlock value={this.state.errors.longitude} />
                  </div>

                  <div
                    className={`form-group ${this.state.errors.timezone !== undefined ? "has-error" : ""
                      }`}
                  >
                    <label htmlFor="">Timezone</label>
                    <CustomizedSelect
                      id="select-timezone"
                      placeholder="Select Timezone"
                      onChange={this.selectTimezone}
                      isDisabled={this.state.disableTimezone}
                      value={this.state.timezone}
                      options={Object.keys(this.props.timezones).map((key) => ({
                        value: this.props.timezones[key],
                        label: this.props.timezones[key],
                      }))}
                    />
                    <HelpBlock value={this.state.errors.timezone} />
                  </div>
                  <div
                    className={`form-group ${this.state.errors.procedures !== undefined ? "has-error" : ""
                      }`}
                  >
                    <label htmlFor="locations">Procedures</label>
                    <CustomMultiSelect
                      id="select-locations"
                      placeholder={this.state.procedures.length ? "Selected" : "Select"}
                      onChange={this.selectProcedures}
                      value={this.state.procedures}
                      options={
                        this.props.procedures.map((item) => ({
                          value: item.id, label: `${item.name}`,
                        }))
                      }
                    />
                    <HelpBlock value={this.state.errors.procedures} />
                  </div>
                </div>
              }}
              buttons={[
                <PrimaryButton onClick={this.state.locationId ? this.onEditLocation : this.onAddLocation}>{this.state.locationId ? "Save" : ""}</PrimaryButton>
              ]}
            />
          </div>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  locations: state.locationAdministration.locationAdministrationReducer.locations,
  isRequestInProgress: state.locationAdministration.locationAdministrationReducer.isRequestInProgress,
  show: state.locationAdministration.locationAdministrationReducer.show,
  error: state.locationAdministration.locationAdministrationReducer.error,
  timezones: state.locationAdministration.locationAdministrationReducer.timezones,
  procedures: state.locationAdministration.getDepartmentProceduresReducer.procedures,
  manageLocationsEnabled: state.currentUser.attributes.hasSuperUserPrivileges && state.currentUser.attributes.isAdmin,
  pagination: state.locationAdministration.locationAdministrationReducer.pagination,
  canAddOnlyProviders: state.currentUser.attributes.canAddOnlyProviders
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllLocationsOfTheDepartment: (params) =>
    dispatch(onfetchAllLocationsOfTheDepartmentRequest(params)),
  addLocation: (params) => dispatch(onAddNewLocationRequest(params)),
  updateLocation: (params) => dispatch(updateLocationAction(params)),
  fetchTimezones: () => dispatch(onfetchTimezonesRequest()),
  getDepartmentProceduresRequest: () => dispatch(getDepartmentProceduresRequest()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "locationAdministration",
  reducer: combineReducers({
    locationAdministrationReducer,
    getDepartmentProceduresReducer,
  }),
});

export default compose(
  withRouter,
  withReducer,
  withConnect
)(LocationAdministration);
