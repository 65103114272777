import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import './confirmationModal.scss';
import { AlertButton, PrimaryButton, SecondaryButton } from 'components/StyleGuideComponents/common/buttons';
import { PrimaryModal } from 'components/StyleGuideComponents/common/modals';

export default class ConfirmationModal extends Component {// eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      show: props.show ? props.show : false,
    };
    this.onModalClose = this.onModalClose.bind(this);
    this.onConfirmed = this.onConfirmed.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      show: nextProps.show,
    });
  }

  onModalClose() {
    this.props.onModalClose();
    this.setState({
      show: false,
    });
  }

  onConfirmed() {
    this.setState({
      show: false,
    });
    this.props.onConfirmed();
  }


  renderRenderButtons() {
    return (
      this.props.isDeleteConfirmation ? (
        <React.Fragment>
          <AlertButton onClick={this.onConfirmed}>
            {this.props.okBtnText}
          </AlertButton>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <SecondaryButton onClick={this.onModalClose}>
            {this.props.cancelBtnText}
          </SecondaryButton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <SecondaryButton onClick={this.onModalClose}>
            {this.props.cancelBtnText}
          </SecondaryButton>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <PrimaryButton onClick={this.onConfirmed}>
            {this.props.okBtnText}
          </PrimaryButton>
        </React.Fragment>
      ));
  }

  render() {
    return (
      <PrimaryModal
        show={this.state.show}
        onHide={this.onModalClose}
        bodyHeader={{
          text: this.props.title
        }}
        bodyContent={{
          text: <>
            <div className="modal-text">{this.props.text}</div>
            <div className="confirm-modal_btns_container float-right">
              {this.renderRenderButtons()}
            </div>
            <div className="clr"></div>
          </>
        }}
      />
    );
  }
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onConfirmed: PropTypes.func.isRequired,
  cancelBtnText: PropTypes.string.isRequired,
  okBtnText: PropTypes.string.isRequired,
  isDeleteConfirmation: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  isDeleteConfirmation: false,
};
