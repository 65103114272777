/**
*
* AddPatientView
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { compose, combineReducers } from 'redux';
import { withRouter } from "react-router-dom";
import injectReducer from 'utils/injectReducer';

import { getProcedureLocationsReducer, getDepartmentOnlyProvidersReducer, locationReducer } from 'common/reducer';
import {
  getProcedureLocationsRequest, getDepartmentOnlyProvidersRequest, getTimezoneLocationRequest, clearLocationReducerData,
} from 'common/actions';

import Datetime from 'react-datetime';
import lodash from 'lodash';
import PhoneInput from 'components/PhoneInput';
import RadioButton from 'components/RadioButton';
import validate, { clearErrorsForField } from 'common/validator';
import HelpBlock from 'components/HelpBlock';
import Select, { SingleSelectWithoutBorder as CustomizedSelect } from 'components/SelectV2';
import { camelCaseToString } from 'utils/stringUtils';
import TimePicker from 'components/TimePicker';
import { patientInfoValidationConfig, procedureValidationConfig, caregiverValidationConfig } from './validators';
import './style.scss';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import { PrimaryModal } from 'components/StyleGuideComponents/common/modals';

class AddPatientView extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    mrn: '',
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    providerId: '',
    procedureId: '',
    locationId: '',
    isLocationKnown: true,
    zipcode: '',
    procedureDate: '',
    procedureTime: '',
    locations: [],
    errors: {},
    activeFormStep: 1,
    caregiverFirstName: '',
    caregiverLastName: '',
    caregiverEmail: '',
    caregiverContactNumber: '',
    caregiverRelationshipWithPatient: '',
    complianceObtained: false,
    totalSteps: 4,
    onlyProviders: [],
    patientAdvocateProviderId: '',
    userProfileName: 'Physician',
    canClearData: false,
    hasDisclaimerError: false,
    isDateSelected: false,
    zipcodeLocation: null,
    hasCaregiverDisclaimerError: false,
    localKVDataStore: {},
  })

  componentWillMount() {
    const { userProfileName } = this.props;

    if ((this.props.hasSuperUserPrivileges || this.props.isSupportUser || (this.props.userType == 'Provider' && this.props.departmentDetails.canProviderSelectRep)) && !procedureValidationConfig.fields.includes('providerId')) {
      procedureValidationConfig.fields.push('providerId');
      procedureValidationConfig.rules.providerId = [
        { rule: 'isRequired', message: `${camelCaseToString(userProfileName, true)} is required` },
      ];
    }

    if ((this.props.userType !== 'Provider' || (this.props.departmentDetails.canProviderSelectRep && this.props.isSupportUser)) && !procedureValidationConfig.fields.includes('patientAdvocateProviderId')) {
      procedureValidationConfig.fields.push('patientAdvocateProviderId');
      procedureValidationConfig.rules.patientAdvocateProviderId = [
        { rule: 'isRequired', message: `Physician is required` },
      ];
    }
  }

  componentDidMount() {
    if (this.props.userType !== 'Provider' || (this.props.departmentDetails.canProviderSelectRep && this.props.isSupportUser)) {
      this.props.getDepartmentOnlyProviders();
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.locations !== nextProps.locations) {
      this.setState({
        locations: nextProps.locations,
      });
    }
    if ((this.state.zipcode.length === 4 && this.props.country === 'AU') || ((this.state.zipcode.length === 5 || this.state.zipcode.length === 10) && this.props.country === 'US')) {
      this.setState({
        zipcodeLocation: nextProps.location,
      });
    } else {
      this.setState({
        zipcodeLocation: null,
      });
    }
    nextProps.locations.find((l) => l.id === this.state.locationId)
      ? this.setState({ locationId: this.state.locationId })
      : this.setState({ locationId: '' });
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  onModalClose = () => {
    this.resetStates();
    this.props.onModalClose();
  }

  getLocalKVDataStoreToSendToServer = () => lodash.mapKeys(this.state.localKVDataStore, (value, key) => lodash.startCase(key))

  onSaveNewPatient = () => {
    this.setState({ errors: {} });

    const {
      mrn, firstName, lastName, email, providerId, procedureId, locationId,
      procedureDate, procedureTime, caregiverFirstName, caregiverLastName, caregiverEmail, caregiverRelationshipWithPatient, complianceObtained, patientAdvocateProviderId,
    } = this.state;

    let caregiverContactNumber = '';

    if (this.state.caregiverContactNumber !== '' && this.state.caregiverContactNumber.length > 0) {
      caregiverContactNumber = this.props.country === 'AU' ?
        this.state.caregiverContactNumber.replace('+61', '') : this.state.caregiverContactNumber.replace('+1', '');
      caregiverContactNumber = caregiverContactNumber.replace(/ /g, '');
    }

    const selectedProcedureDate = procedureDate !== '' && procedureTime !== null ? procedureDate.format('YYYY-MM-DD') : '';
    const selectedProcedureTime = procedureTime !== '' && procedureTime !== null ? procedureTime.format('HH:mm A') : '';
    const data = {
      mrn: mrn.trim(),
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      procedure_id: procedureId,
      date: selectedProcedureDate,
      date_known: selectedProcedureDate !== '' ? '1' : '0',
      time: selectedProcedureTime,
      time_known: selectedProcedureTime !== '' ? '1' : '0',
      compliance_obtained: complianceObtained,
      caregiver: {
        first_name: caregiverFirstName.trim(),
        last_name: caregiverLastName.trim(),
        relationship_with_patient: caregiverRelationshipWithPatient,
      },
      kv_datastore: this.getLocalKVDataStoreToSendToServer(),
    };

    const emailValue = email.trim();
    if (emailValue !== '') {
      data.email = emailValue;
    }

    if (this.state.isLocationKnown) {
      data.location_id = locationId;
      data.is_location_tbd = false;
    } else {
      data.is_location_tbd = true;
      data.location = this.state.zipcodeLocation;
      data.location.zipcode = this.state.zipcode.trim();
    }

    if (this.props.userType !== 'Provider') {
      data.patient_advocate_id = providerId || this.props.currentUserId;
      data.provider_id = patientAdvocateProviderId;
    } else {
      if (this.props.departmentDetails.canProviderSelectRep) {
        data.patient_advocate_id = providerId;
        data.provider_id = patientAdvocateProviderId || this.props.currentUserId;
      } else {
        data.provider_id = providerId || this.props.currentUserId;
      }
    }

    let contactNumber = this.props.country === 'AU' ?
      this.state.contactNumber.replace('+61', '') : this.state.contactNumber.replace('+1', '');
    contactNumber = contactNumber.replace(/ /g, '');

    if (contactNumber !== '') {
      data.contact_number = contactNumber;
    }
    if (caregiverContactNumber !== '') {
      data.caregiver.contact_number = caregiverContactNumber;
    }
    const caregiverEmailValue = caregiverEmail.trim();
    if (caregiverEmailValue !== '') {
      data.caregiver.email = caregiverEmailValue;
    }

    this.props.checkFromPropsCall(true);
    this.props.onSaveNewPatientRequest(data);
  }

  onFormValidationFailure = (errors) => {
    this.setState({ errors });
  }

  onNextClick = () => {
    const {
      caregiverFirstName, caregiverLastName, caregiverEmail, caregiverContactNumber, complianceObtained,
    } = this.state;
    const config = cloneDeep(this.state.activeFormStep === 1 ?
      patientInfoValidationConfig : (this.state.activeFormStep === 2 ?
        procedureValidationConfig : caregiverValidationConfig));

    if (this.state.activeFormStep === 1) {
      if (this.props.country === 'AU') {
        delete config.rules.contactNumber;
        config.rules.contactNumber = [
          { rule: 'isAuPhone', message: 'Contact number is invalid' },
        ];
      }
      validate(config, this.state, this.onNextValidationFailure, this.onNextValidationSuccess);
    } else if (this.state.activeFormStep === 2) {
      if (!this.state.isLocationKnown) {
        if (config.fields.includes('locationId')) {
          config.fields = config.fields.filter((item) => item !== 'locationId');
          delete config.rules.locationId;
        }
        if (!config.fields.includes('zipcode')) {
          config.fields = config.fields.concat('zipcode');
          config.rules.zipcode = [
            { rule: 'isRequired', message: 'Zipcode is required' },
            { rule: 'isZipCode', message: 'Please enter a valid Zip code format', country: this.props.country },
          ];
        }
      } else {
        if (config.fields.includes('zipcode')) {
          config.fields = config.fields.filter((item) => item !== 'zipcode');
          delete config.rules.zipcode;
        }
        if (!config.fields.includes('locationId')) {
          config.fields = config.fields.concat('locationId');
          config.rules.locationId = [
            { rule: 'isRequired', message: 'Location is required' },
          ];
        }
      }

      validate(config, this.state, this.onNextValidationFailure, this.onNextValidationSuccess);
    } else if (this.state.activeFormStep === 3 && (caregiverFirstName !== '' || caregiverLastName !== '' || caregiverEmail !== '' || caregiverContactNumber !== '' || complianceObtained)) {
      if (this.props.country === 'AU') {
        delete config.rules.caregiverContactNumber;
        config.rules.caregiverContactNumber = [
          { rule: 'isAuPhone', message: 'Contact number is invalid' },
          { rule: 'requiredDifferentValue', fieldsName: ['contactNumber', 'caregiverContactNumber'], message: "Caregiver's phone number should not be same as Patient's phone number" },
        ];
      }
      validate(config, this.state, this.onNextValidationFailure, this.onNextValidationSuccess);
    } else {
      this.setState((prevState) => ({ activeFormStep: prevState.activeFormStep + 1 }));
    }
  }

  onNextValidationFailure = (errors) => {
    this.setState({ errors });
  }

  onPrevClick = () => {
    this.setState((prevState) => ({ activeFormStep: prevState.activeFormStep - 1 }));
  }

  onComplianceClick = () => {
    this.setState({ errors: clearErrorsForField(this.state.errors, 'complianceObtained') });
    if (this.state.complianceObtained) {
      this.setState({
        complianceObtained: false,
      });
    } else {
      this.setState({
        complianceObtained: true,
      });
    }
  }

  onClearCaregiverData = () => {
    this.setState({
      caregiverFirstName: '',
      caregiverLastName: '',
      caregiverEmail: '',
      caregiverContactNumber: '',
      complianceObtained: false,
    });
  }

  onNextValidationSuccess = () => {
    if (this.state.activeFormStep === 1) {
      const {
        email, contactNumber,
      } = this.state;

      if (email === '' && contactNumber === '') {
        this.setState({
          hasDisclaimerError: true,
        });
      } else {
        this.setState({
          hasDisclaimerError: false,
        });
        this.setState((prevState) => ({ activeFormStep: prevState.activeFormStep + 1 }));
      }
    } else if (this.state.activeFormStep === 3) {
      const {
        caregiverEmail, caregiverContactNumber,
      } = this.state;
      if (caregiverEmail === '' && caregiverContactNumber === '') {
        this.setState({
          hasCaregiverDisclaimerError: true,
        });
      } else {
        this.setState({
          hasCaregiverDisclaimerError: false,
        });
        this.setState((prevState) => ({ activeFormStep: prevState.activeFormStep + 1 }));
      }
    } else {
      this.setState((prevState) => ({ activeFormStep: prevState.activeFormStep + 1 }));
    }
  }

  onPrevClick = () => {
    this.setState((prevState) => ({ activeFormStep: prevState.activeFormStep - 1 }));
  }

  onComplianceClick = () => {
    this.setState({ errors: clearErrorsForField(this.state.errors, 'complianceObtained') });
    if (this.state.complianceObtained) {
      this.setState({
        complianceObtained: false,
      });
    } else {
      this.setState({
        complianceObtained: true,
      });
    }
  }

  onZipCodeValidationFailure = (errors) => {
    this.setState({ errors }, () => {
      this.props.clearData();
    });
  }

  onZipCodeValidationSuccess = () => {
    if ((this.state.zipcode.length === 4 && this.props.country === 'AU') || ((this.state.zipcode.length === 5 || this.state.zipcode.length === 10) && this.props.country === 'US')) {
      this.props.fetchLocationRequest({ postal_code: this.state.zipcode });
    }
  }

  onClearCaregiverData = () => {
    this.setState({
      caregiverFirstName: '',
      caregiverLastName: '',
      caregiverEmail: '',
      caregiverContactNumber: '',
      caregiverRelationshipWithPatient: '',
      complianceObtained: false,
    });
  }

  onLocationKnownToggle = (value) => {
    if (value) {
      this.props.clearData();
    } else if (this.state.zipcode !== '') {
      this.onZipCodeValidationSuccess();
    }
    this.setState({
      isLocationKnown: value,
      errors: !value ? clearErrorsForField(this.state.errors, 'locationId') : clearErrorsForField(this.state.errors, 'zipcode'),
    });
  }

  onChangeZipCode = (e) => {
    const { value } = e.target;
    const config = {
      fields: ['zipcode'],
      rules: {
        zipcode: [
          { rule: 'isRequired', message: 'Zipcode is required' },
          { rule: 'isZipCode', message: 'Please enter a valid Zip code format', country: this.props.country },
        ],
      },
    };
    this.setState({
      zipcode: value,
      zipcodeLocation: null,
      errors: clearErrorsForField(this.state.errors, 'zipcode'),
    }, () => {
      if (value.length >= 3) {
        validate(config, this.state, this.onZipCodeValidationFailure, this.onZipCodeValidationSuccess);
      }
    });
  }

  changeKVDataStoreHandler = (e) => {
    const kvDataStore = { ...this.state.localKVDataStore };
    kvDataStore[e.target.name] = e.target.value;
    this.setState({
      localKVDataStore: kvDataStore,
    });
  };

  selectPerformer = ({ value }) => {
    this.setState({ providerId: value, errors: clearErrorsForField(this.state.errors, 'providerId') });
  }

  selectProcedure = ({ value }) => {
    if (value !== null && value !== '') {
      this.setState({ procedureId: value, errors: clearErrorsForField(this.state.errors, 'procedureId') });
      const params = {
        id: value,
        provider_id: this.state.patientAdvocateProviderId ? this.state.patientAdvocateProviderId : null,
      };

      const selectedProcedure = this.props.procedures.find((item) => item.id === parseInt(value, 10));

      if (selectedProcedure && selectedProcedure.locations.length === 1) {
        this.setState({
          locationId: selectedProcedure.locations[0].id,
          errors: clearErrorsForField(this.state.errors, 'locationId'),
        });
      }
      this.props.getProcedureLocationsRequest(params);
    }
  }

  selectProvider = (options) => {
    this.setState({ errors: clearErrorsForField(this.state.errors, 'patientAdvocateProviderId'), patientAdvocateProviderId: options ? options.value : '' });

    if (this.state.procedureId !== null && this.state.procedureId !== '') {
      const params = {
        id: this.state.procedureId,
        provider_id: options.value,
      };
      this.props.getProcedureLocationsRequest(params);
    }
  }

  selectLocations = ({ value }) => {
    this.setState({
      locationId: value,
      errors: clearErrorsForField(this.state.errors, 'locationId'),
    });
  }

  changeFieldHandler = (e) => {
    const obj = {};
    obj[e.target.name] = e.target.name !== 'caregiverRelationshipWithPatient' ? e.target.value.trim() : e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, e.target.name);
    this.setState(obj);
  }

  resetStates = () => {
    this.setState(this.getInitialState());
  }

  changeEmailFieldHandler = (e) => {
    const obj = {};
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, e.target.name);
    obj.hasDisclaimerError = false;
    obj.hasCaregiverDisclaimerError = false;
    this.setState(obj);
  }

  changeContactNumberHandler = (e) => {
    const contactNumber = e.target.value.trim();

    this.setState({
      contactNumber,
      hasDisclaimerError: false,
      errors: clearErrorsForField(this.state.errors, 'contactNumber'),
    });
  }

  changeCaregiverContactNumberHandler = (e) => {
    const contactNumber = e.target.value;
    this.setState({
      caregiverContactNumber: contactNumber,
      hasCaregiverDisclaimerError: false,
      errors: clearErrorsForField(this.state.errors, 'caregiverContactNumber'),
    });
  }


  renderLocationOption = () => {
    const options = this.state.locations && this.state.locations.map((item) => ({
      value: item.id, label: item.name,
    }));

    const locationOption = options.find((item) => item.value === parseInt(this.state.locationId, 10));

    return (
      <div className={`form-group ${this.state.errors.locationId !== undefined ? 'has-error' : ''}`}>
        <label htmlFor="location">Location</label>
        <CustomizedSelect
          id="select-location"
          placeholder="Location"
          isLoading={this.props.isLoading}
          onChange={this.selectLocations}
          value={this.state.locationId ? locationOption : { value: null, label: null }}
          options={options}
        />
        <HelpBlock value={this.state.errors.locationId} />
      </div>
    );
  }


  renderLocationToggle = () => (
    <React.Fragment>
      <div className="no-padding location-container">
        <div className="float-left">Location Known?&nbsp;&nbsp;&nbsp;&nbsp;</div>
        <div className="float-right">
          <RadioButton
            onToggle={this.onLocationKnownToggle}
            value={this.state.isLocationKnown}
            defaultValue={this.state.isLocationKnown}
          />
        </div>
        <div className="clearfix" />
      </div>

      {
        this.state.isLocationKnown ? this.renderLocationOption() : this.renderZipCodeTextBox()
      }
    </React.Fragment>
  )

  renderSupportedKeys = () => this.props.departmentDetails.supportedKeys.map((item, index) => (
    <div className="form-group" key={index}>
      <label>{lodash.startCase(item)}</label>
      <input
        type="text"
        id={`${item}_id`}
        name={lodash.camelCase(item)}
        className="form-control"
        placeholder=""
        value={this.state.localKVDataStore[lodash.camelCase(item)]}
        onChange={this.changeKVDataStoreHandler}
      />
    </div>
  ))


  renderZipCodeTextBox = () => {
    const { isZipcodeLoading } = this.props;
    const class1 = `form-control ${isZipcodeLoading ? 'loading' : ''}`;
    return (
      <div className="zipcode-wrapper">
        <div className={`form-group ${this.state.errors.zipcode !== undefined ? 'has-error' : ''}`}>
          <label htmlFor="zipcode">Zip Code</label>
          <input
            type="text"
            className={class1}
            value={this.state.zipcode}
            placeholder="Enter Zip Code"
            onChange={this.onChangeZipCode}
            name="zipcode"
          />
          <HelpBlock value={this.state.errors.zipcode} />
          {
            this.props.hasError ? (
              <div className="location-name app-red">
                {this.props.errorMsg}
              </div>
            ) :
              (
                <React.Fragment>
                  {
                    this.state.zipcodeLocation !== null && (
                      <div className="location-name">
                        Location :
                        {this.state.zipcodeLocation.name !== undefined ? `${this.state.zipcodeLocation.name}, ${this.state.zipcodeLocation.title}` : 'No results found'}
                      </div>
                    )
                  }
                </React.Fragment>
              )
          }
        </div>
      </div>
    );
  }

  getButtonsArray = () => {
    let arr = []
    if (this.state.activeFormStep > 1)
      arr.push(<PrimaryButton onClick={this.onPrevClick}>Previous</PrimaryButton>)
    if (this.state.activeFormStep < this.state.totalSteps)
      arr.push(<PrimaryButton
        disabled={
          this.state.activeFormStep === 2 && (this.props.isZipcodeLoading || this.props.hasError || (!this.state.isLocationKnown && this.state.zipcodeLocation && this.state.zipcodeLocation.name === undefined))
        }
        onClick={this.onNextClick}
      >
        Next
      </PrimaryButton>)
    if (this.state.activeFormStep === this.state.totalSteps)
      arr.push(<PrimaryButton onClick={this.onSaveNewPatient}> Add </PrimaryButton>)
    return arr;
  }


  render() {
    const {
      caregiverFirstName, caregiverLastName, caregiverEmail, caregiverContactNumber, caregiverRelationshipWithPatient, complianceObtained, procedureDate, procedureTime,
    } = this.state;
    const { userProfileName } = this.props;
    const canClearData = !!((caregiverFirstName !== '' || caregiverLastName !== '' || caregiverEmail !== '' || caregiverContactNumber !== '' || caregiverRelationshipWithPatient !== '' || complianceObtained));

    const proc = this.props.procedures.length > 0 && this.props.procedures.find((pr) => pr.id === parseInt(this.state.procedureId, 10));
    const phy = this.props.patientAdvocates.length > 0 && this.props.patientAdvocates.find((ph) => ph.id === parseInt(this.state.providerId, 10));
    const patientAdvocatesPhy = this.props.onlyProviders.length > 0 && this.props.onlyProviders.find((ph) => ph.id === parseInt(this.state.patientAdvocateProviderId, 10));
    const loc = this.state.isLocationKnown ? (this.props.locations.length > 0 && this.props.locations.find((l) => l.id === parseInt(this.state.locationId, 10))) : this.state.zipcodeLocation;
    const summaryProcedureDate = procedureDate && procedureDate !== '' ? procedureDate.format('ddd, MMM D, YYYY') : '- -';
    const summaryProcedureTime = procedureTime && procedureTime !== '' ? procedureTime.format('h:mm A') : '- -';

    return (
      <PrimaryModal
        show={this.props.show}
        onHide={this.onModalClose}
        bodyHeader={{
          text: "Add New Patient"
        }}
        bodyContent={{
          text: <div className="add-patient-modal">

            <div className="row modal-subheader-content">
              <div className="col-sm-9 col-xs-8 padding-left">
                {
                  this.state.activeFormStep !== this.state.totalSteps && (
                    <div>
                      {`Add ${this.state.activeFormStep === 1 ? 'Patient Information' : (this.state.activeFormStep === 2 ? 'Procedure Details' : 'Caregiver (optional)')}`}
                    </div>
                  )
                }
                {
                  this.state.activeFormStep === 4 &&
                  <div>Patient Summary</div>
                }
              </div>
              <div className="col-sm-3 col-xs-4 text-right padding-right">
                <span className="steps-count active-step-count">{this.state.activeFormStep}</span>
                &nbsp;
                <span className="separation">of</span>
                &nbsp;
                <span className="steps-count">{this.state.totalSteps}</span>
              </div>
            </div>

            <div className={`step-1 ${this.state.activeFormStep === 1 ? 'show' : 'hide'}`}>
              <div className={`form-group ${this.state.errors.mrn !== undefined ? 'has-error' : ''}`}>
                <label htmlFor="mrn">MRN</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.mrn}
                  placeholder="MRN"
                  onChange={this.changeFieldHandler}
                  name="mrn"
                />
                <HelpBlock value={this.state.errors.mrn} />
              </div>
              <div className={`form-group ${this.state.errors.firstName !== undefined ? 'has-error' : ''}`}>
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.firstName}
                  placeholder="First Name"
                  onChange={this.changeFieldHandler}
                  name="firstName"
                />
                <HelpBlock value={this.state.errors.firstName} />
              </div>
              <div className={`form-group ${this.state.errors.lastName !== undefined ? 'has-error' : ''}`}>
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.lastName}
                  placeholder="Last Name"
                  onChange={this.changeFieldHandler}
                  name="lastName"
                />
                <HelpBlock value={this.state.errors.lastName} />
              </div>
              <div className={`email-contactnumber-disclaimer ${this.state.hasDisclaimerError ? 'has-error' : ''}`}>Note: Specify mobile phone number or email</div>
              <div className={`form-group ${this.state.errors.contactNumber !== undefined ? 'has-error' : ''}`}>
                <label htmlFor="phone">Mobile Phone Number (preferred)</label>
                <PhoneInput
                  className="form-control"
                  id="phone"
                  name="contactNumber"
                  placeholder={this.props.country === 'AU' ? '+61 02 3456 7890' : '+1 123 456 7890'}
                  value={this.state.contactNumber}
                  onChange={this.changeContactNumberHandler}
                  country={this.props.country}
                />
                <HelpBlock value={this.state.errors.contactNumber} />
              </div>
              <div className={`form-group ${this.state.errors.email !== undefined ? 'has-error' : ''}`}>
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.email}
                  placeholder="Email"
                  onChange={this.changeEmailFieldHandler}
                  name="email"
                />
                <HelpBlock value={this.state.errors.email} />
              </div>
            </div>

            <div className={`step-2 ${this.state.activeFormStep === 2 ? 'show' : 'hide'}`}>
              {
                (this.props.hasSuperUserPrivileges || this.props.isSupportUser || (this.props.userType == 'Provider' && this.props.departmentDetails.canProviderSelectRep)) &&
                (
                  <div className={`form-group ${this.state.errors.providerId !== undefined ? 'has-error' : ''}`}>
                    <label htmlFor="performer">
                      {`Select ${camelCaseToString(userProfileName, true)}`}
                    </label>
                    <CustomizedSelect
                      id="select-performer"
                      placeholder={`Select ${camelCaseToString(userProfileName, true)}`}
                      onChange={this.selectPerformer}
                      options={
                        this.props.patientAdvocates.map((item) => ({
                          value: item.id, label: `${item.firstName} ${item.lastName}`,
                        }))
                      }
                    />
                    <HelpBlock value={this.state.errors.providerId} />
                  </div>
                )
              }

              <div className={`form-group ${this.state.errors.procedureId !== undefined ? 'has-error' : ''}`}>
                <label htmlFor="procedure">Select Procedure</label>
                <CustomizedSelect
                  id="select-procedure"
                  placeholder="Select Procedure"
                  onChange={this.selectProcedure}
                  options={
                    this.props.procedures.map((item) => ({
                      value: item.id, label: item.name,
                    }))
                  }
                />
                <HelpBlock value={this.state.errors.procedureId} />
              </div>

              {
                (this.props.userType !== 'Provider' || (this.props.departmentDetails.canProviderSelectRep && this.props.isSupportUser)) &&
                (
                  <div className={`form-group ${this.state.errors.patientAdvocateProviderId !== undefined ? 'has-error' : ''}`}>
                    <label htmlFor="patientAdvocateProviderId">Select Physician</label>
                    <CustomizedSelect
                      id="select-physician"
                      placeholder="Select Physician"
                      onChange={this.selectProvider}
                      options={
                        this.props.onlyProviders && this.props.onlyProviders.map((item) => ({
                          value: item.id, label: `${item.firstName} ${item.lastName}`,
                        }))
                      }
                    />
                    <HelpBlock value={this.state.errors.patientAdvocateProviderId} />
                  </div>
                )
              }
              {
                this.props.userType !== 'Provider' ?
                  this.renderLocationToggle() : this.renderLocationOption()
              }
              <div className="procedure-date-info">Note: Leave blank if the procedure date and time are yet to be decided.</div>

              <div className="form-group">
                <div className="row">
                  <div className={`col-sm-6 col-xs-12 ${this.state.errors.procedureDate !== undefined ? 'has-error' : ''}`}>
                    <label htmlFor="procedureDate">Appointment Date</label>
                    <Datetime
                      timeFormat={false}
                      className="date-picker-field float-left date-field form-control"
                      inputProps={{ placeholder: 'Appointment Date', readOnly: true }}
                      closeOnSelect
                      closeOnTab
                      onChange={(date) => {
                        this.setState({ procedureDate: date, isDateSelected: true, errors: clearErrorsForField(this.state.errors, 'procedureDate') });
                      }}
                    />
                    <HelpBlock value={this.state.errors.procedureTime} />
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    <div className="visible-xs margin-top-10"></div>
                    <label htmlFor="procedureTime">Appointment Time</label>
                    <TimePicker
                      disabled={!this.state.isDateSelected}
                      placeholder="Appointment Time"
                      onChange={(time) => this.setState({ procedureTime: time, errors: clearErrorsForField(this.state.errors, 'procedureTime') })}
                    />
                  </div>
                </div>
              </div>

              {
                this.props.departmentDetails.supportedKeys !== null ? this.renderSupportedKeys() : null
              }

            </div>


            <div className={`step-3 ${this.state.activeFormStep === 3 ? 'show' : 'hide'}`}>

              <div className="caregiver-disclaimer-info">Note: Leave blank if caregiver is yet to be decided.</div>
              <div className="clear-data">
                <i
                  role="button"
                  tabIndex="0"
                  className={`icon icon-font-a-clear-all ${canClearData ? 'active-icon cursor-pointer' : 'deactive-icon'}`}
                  onClick={this.onClearCaregiverData}
                />
                <span
                  role="button"
                  tabIndex="0"
                  className={`hidden-xs clear-label ${canClearData ? 'active-label cursor-pointer' : 'deactive-label'}`}
                  onClick={this.onClearCaregiverData}
                >
                  Clear
                </span>
              </div>
              <div className="clearfix"></div>

              <div className={`form-group ${this.state.errors.caregiverFirstName !== undefined ? 'has-error' : ''}`}>
                <label htmlFor="caregiverFirstName">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.caregiverFirstName}
                  placeholder="First Name"
                  onChange={this.changeFieldHandler}
                  name="caregiverFirstName"
                />
                <HelpBlock value={this.state.errors.caregiverFirstName} />
              </div>
              <div className={`form-group ${this.state.errors.caregiverLastName !== undefined ? 'has-error' : ''}`}>
                <label htmlFor="caregiverLastName">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.caregiverLastName}
                  placeholder="Last Name"
                  onChange={this.changeFieldHandler}
                  name="caregiverLastName"
                />
                <HelpBlock value={this.state.errors.caregiverLastName} />
              </div>
              <div className={`email-contactnumber-disclaimer ${this.state.hasCaregiverDisclaimerError ? 'has-error' : ''}`}>Note: Specify mobile phone number or email</div>
              <div className={`form-group ${this.state.errors.caregiverContactNumber !== undefined ? 'has-error' : ''}`}>
                <label htmlFor="caregiverContactNumber">Mobile Phone Number (preferred)</label>
                <PhoneInput
                  className="form-control"
                  id="caregiverContactNumber"
                  name="caregiverContactNumber"
                  country={this.props.country}
                  placeholder={this.props.country === 'AU' ? '+61 02 3456 7890' : '+1 123 456 7890'}
                  value={this.state.caregiverContactNumber}
                  onChange={this.changeCaregiverContactNumberHandler}
                />
                <HelpBlock value={this.state.errors.caregiverContactNumber} />
              </div>
              <div className={`form-group ${this.state.errors.caregiverEmail !== undefined ? 'has-error' : ''}`}>
                <label htmlFor="caregiverEmail">Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.caregiverEmail}
                  placeholder="Email"
                  onChange={this.changeEmailFieldHandler}
                  name="caregiverEmail"
                />
                <HelpBlock value={this.state.errors.caregiverEmail} />
              </div>
              <div className="form-group">
                <label htmlFor="relationshipWithPatient">Relationship With Patient</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.caregiverRelationshipWithPatient}
                  placeholder="Relationship With Patient"
                  onChange={this.changeFieldHandler}
                  name="caregiverRelationshipWithPatient"
                />
              </div>
              <div className={`checkbox-container form-group ${this.state.errors.complianceObtained !== undefined ? 'has-error' : ''}`}>
                <span role="button" tabIndex="0" className="cursor-pointer compliance-icon" onClick={this.onComplianceClick}>
                  <i className={`icon ${this.state.complianceObtained ? 'icon-font-a-check-mark' : 'icon-font-a-check-box'}`}></i>
                </span>
                <div className="check-box-label">We have the necessary Authorization for Disclosure of Protected Health Information under HIPAA to add the caregiver(s) for this patient.</div>
                <HelpBlock value={this.state.errors.complianceObtained} />
              </div>
            </div>


            <div className={`step-4 ${this.state.activeFormStep === this.state.totalSteps ? 'show' : 'hide'}`}>
              <div className="summary-wrapper">
                <div className="add-patient-summary-title">Patient Information</div>
                <div className="add-patient-summary-row">
                  <span className="summary-label">MRN</span>
                  <span className="summary-row-value">{this.state.mrn !== '' ? this.state.mrn : '- -'}</span>
                </div>
                <div className="add-patient-summary-row">
                  <span className="summary-label">Name</span>
                  <span className="summary-row-value">
                    {`${this.state.firstName} ${this.state.lastName}`}
                  </span>
                </div>
                <div className="add-patient-summary-row">
                  <span className="summary-label">Mobile Phone Number (preferred)</span>
                  <span className="summary-row-value">{this.state.contactNumber !== '' ? this.state.contactNumber : '- -'}</span>
                </div>
                <div className="add-patient-summary-row">
                  <span className="summary-label">Email</span>
                  <span className="summary-row-value">{this.state.email !== '' ? this.state.email : '- -'}</span>
                </div>
                <div className="summary-separation"></div>
                <div className="add-patient-summary-title">Procedure Details</div>
                <div className="add-patient-summary-row">
                  <span className="summary-label">Procedure</span>
                  <span className="summary-row-value">{proc ? proc.name : '- -'}</span>
                </div>
                {
                  (this.props.hasSuperUserPrivileges || this.props.isSupportUser || (this.props.userType == 'Provider' && this.props.departmentDetails.canProviderSelectRep)) &&
                  (
                    <div className="add-patient-summary-row">
                      <span className="summary-label">{camelCaseToString(userProfileName, true)}</span>
                      <span className="summary-row-value">{phy ? `${phy.firstName} ${phy.lastName}` : '- -'}</span>
                    </div>
                  )
                }
                {
                  this.props.userType !== 'Provider' || (this.props.isSupportUser && this.props.departmentDetails.canProviderSelectRep) &&
                  (
                    <div className="add-patient-summary-row">
                      <span className="summary-label">Physician</span>
                      <span className="summary-row-value">
                        {patientAdvocatesPhy ? `${patientAdvocatesPhy.firstName} ${patientAdvocatesPhy.lastName}` : '- -'}
                      </span>
                    </div>
                  )
                }
                <div className="add-patient-summary-row">
                  <span className="summary-label">Location</span>
                  <span className="summary-row-value">{loc ? `${loc.name}, ${loc.title}` : '- -'}</span>
                </div>
                <div className="add-patient-summary-row">
                  <span className="summary-label">Appointment Date</span>

                  <span className="summary-row-value">{summaryProcedureDate}</span>
                </div>
                <div className="add-patient-summary-row">
                  <span className="summary-label">Appointment Time</span>

                  <span className="summary-row-value">{summaryProcedureTime}</span>
                </div>
                {
                  this.props.departmentDetails.supportedKeys.map((item, index) => (
                    <div className="add-patient-summary-row">
                      <span className="summary-label">{lodash.startCase(item)}</span>
                      <span className="summary-row-value">{(this.state.localKVDataStore[item] === null || this.state.localKVDataStore[item] === undefined) ? '- -' : this.state.localKVDataStore[item]}</span>
                    </div>
                  ))
                }
                <div className="summary-separation"></div>
                <div className="add-patient-summary-title">Caregiver Details</div>

                <div className="add-patient-summary-row">
                  <span className="summary-label">Name</span>
                  <span className="summary-row-value">{this.state.complianceObtained ? `${this.state.caregiverFirstName} ${this.state.caregiverLastName}` : '- -'}</span>
                </div>
                <div className="add-patient-summary-row">
                  <span className="summary-label">Mobile Phone Number (preferred)</span>
                  <span className="summary-row-value">{this.state.caregiverContactNumber !== '' ? this.state.caregiverContactNumber : '- -'}</span>
                </div>
                <div className="add-patient-summary-row">
                  <span className="summary-label">Email</span>
                  <span className="summary-row-value">{this.state.caregiverEmail !== '' ? this.state.caregiverEmail : '- -'}</span>
                </div>
                <div className="add-patient-summary-row">
                  <span className="summary-label">Relationship With Patient</span>
                  <span className="summary-row-value">{this.state.caregiverRelationshipWithPatient !== '' ? this.state.caregiverRelationshipWithPatient : '- -'}</span>
                </div>
              </div>

            </div>
          </div>
        }}

        buttons={this.getButtonsArray()}
      />
    );
  }
}

AddPatientView.propTypes = {
  locations: PropTypes.array,
  procedures: PropTypes.array,
  patientAdvocates: PropTypes.array,
  onlyProviders: PropTypes.array,
  onModalClose: PropTypes.func,
  hasSuperUserPrivileges: PropTypes.bool,
  isSupportUser: PropTypes.bool,
  show: PropTypes.bool,
  userType: PropTypes.string,
  userProfileName: PropTypes.string,
  checkFromPropsCall: PropTypes.func,
  onSaveNewPatientRequest: PropTypes.func,
  getProcedureLocationsRequest: PropTypes.func,
  getDepartmentOnlyProviders: PropTypes.func,
  currentUserId: PropTypes.number,
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  isZipcodeLoading: PropTypes.bool,
  fetchLocationRequest: PropTypes.func,
  clearData: PropTypes.func,
  hasError: PropTypes.bool,
  errorMsg: PropTypes.string,
  departmentDetails: PropTypes.object,
  country: PropTypes.string,
};

const mapStateToProps = (state) => ({
  locations: state.addPatient.getProcedureLocationsReducer.locations,
  isLoading: state.addPatient.getProcedureLocationsReducer.isLoading,
  location: state.addPatient.locationReducer.result,
  hasError: state.addPatient.locationReducer.hasError,
  errorMsg: state.addPatient.locationReducer.errorMsg,
  isZipcodeLoading: state.addPatient.locationReducer.isLoading,
  userProfileName: state.currentUser.attributes.userProfileName,
  userType: state.currentUser.attributes.type,
  onlyProviders: state.addPatient.getDepartmentOnlyProvidersReducer.onlyProviders,
  currentUserId: state.currentUser.attributes.id,
  country: state.currentUser.attributes.country,
});

const mapDispatchToProps = (dispatch) => ({
  getProcedureLocationsRequest: (params) => dispatch(getProcedureLocationsRequest(params)),
  getDepartmentOnlyProviders: (params) => dispatch(getDepartmentOnlyProvidersRequest(params)),
  fetchLocationRequest: (params) => dispatch(getTimezoneLocationRequest(params)),
  clearData: () => dispatch(clearLocationReducerData()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'addPatient',
  reducer: combineReducers({
    getProcedureLocationsReducer, getDepartmentOnlyProvidersReducer, locationReducer,
  }),
});

export default compose(
  withReducer,
  withConnect,
  withRouter,
)(AddPatientView);
