import axios from './base';
import {
  GET_ALL_USERS_OF_THE_DEPARTMENT,
  ADD_NEW_PROVIDER,
  UPDATE_PROVIDER_INFO,
  GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER,
  GET_ALL_LOCATIONS
} from "./constants";

export const fetchAllUsersOfTheDepartment = (params) =>
  axios.get(GET_ALL_USERS_OF_THE_DEPARTMENT, { params });

export const addNewProvider = (params) => axios.post(ADD_NEW_PROVIDER, params);

export const updateProviderInfo = (params) => {
  const url = UPDATE_PROVIDER_INFO.replace(':id', params.id);
  return axios.put(url, params);
};

export const fetchAllPerformersOfUserTypeMaster = () => {
  return axios.get(GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER);
}

export const fetchAllLocations = () =>
  axios.get(GET_ALL_LOCATIONS);
