import 'whatwg-fetch';
import axios from './base';
import envConstants from 'envConstants.js';

import {
  GET_DEPARTMENT_PROCEDURES,
  GET_DEPARTMENT_PROVIDERS,
  SAVE_SEARCH_FILTER,
  UPDATE_SEARCH_FILTER,
  DELETE_SEARCH_FILTER,
  GET_DEPARTMENT_ONLY_PROVIDERS,
  ADD_NEW_PATIENT_PROCEDURE,
  GET_LOCATION,
  GET_TIMEZONE,
  GET_LOCATION_TIMEZONE,
  GET_DEPARTMENT_DETAILS,
} from './constants';

export const getDepartmentProcedures = () => axios.get(GET_DEPARTMENT_PROCEDURES);

export const getDepartmentProviders = () => axios.get(GET_DEPARTMENT_PROVIDERS);

export const getDepartmentOnlyProviders = () => axios.get(GET_DEPARTMENT_ONLY_PROVIDERS);

export const saveFilterSelection = (params) => axios.post(SAVE_SEARCH_FILTER, params);

export const updateFilterSelection = (params) => {
  const url = UPDATE_SEARCH_FILTER.replace(':id', params.id);
  return axios.put(url, params);
};

export const deleteCustomFilter = (id) => {
  const url = DELETE_SEARCH_FILTER.replace(':id', id);
  return axios.delete(url);
};

export const addNewPatientProcedure = (params) => axios.post(ADD_NEW_PATIENT_PROCEDURE, params);

export const getLocation = (params) => fetch(`${GET_LOCATION}?components=country%3AUS|postal_code%3A${params.postal_code}&key=${envConstants.MAP_KEY}`);

export const getTimezone = (params) => fetch(`${GET_TIMEZONE}?location=${params.location}&timestamp=${params.timestamp}&key=${envConstants.MAP_KEY}`);

export const getLocationTimezone = (params) => axios.get(GET_LOCATION_TIMEZONE, { params });

export const getDepartmentDetails = () => axios.get(GET_DEPARTMENT_DETAILS);
