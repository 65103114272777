import { isiOS } from 'utils/common';

export const USER_TYPE_PATIENT = 'Patient';

export const USER_TYPE_PROVIDER = 'Provider';

export const USER_TYPE_PATIENT_ADVOCATE = 'PatientAdvocate';

export const USER_TYPE_CAREGIVER = 'Caregiver';

export const RESET_GLOBAL_REDUCERS = 'RESET_GLOBAL_REDUCERS';

export const SEARCHABLE_QUESTION_QUERY_LENGTH = 3;


export const IMAGE_FILE_FORMATS = ['image/jpg', 'image/bmp', 'image/gif', 'image/png', 'image/jpeg', 'image/tiff'];

export const DOC_FILE_FORMATS = ['application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/x-msi'];

export const AUDIO_FORMATS = ['audio/x-ms-wma', 'audio/mpeg', 'audio/mp4', 'audio/wavpack'];

export const MS_DOC_EXTENSIONS = ['application/msword', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint'];

export const ALL_FILE_FORMATS = IMAGE_FILE_FORMATS.concat(DOC_FILE_FORMATS).concat(AUDIO_FORMATS).concat(MS_DOC_EXTENSIONS);

export const COMMUNICATION_LOG_SUPPORTED_FILE_FORMATS = isiOS ? '*/*' : ALL_FILE_FORMATS.toString();

export const CARECONVOY_LEARN_MORE_URL = 'https://www.higgsbosonhealth.com/careconvoy'
