
import * as Constants from './authConstants';
const actions = {};

actions.registrationRequestSent = () => ({
  type: Constants.REGISTRATION_REQUEST_SENT,
});

actions.registrationRequestSucceeded = (userAttributes) => ({
  type: Constants.REGISTRATION_REQUEST_SUCCEEDED,
  payload: {
    userAttributes,
  },
});

actions.registrationRequestFailed = () => ({
  type: Constants.REGISTRATION_REQUEST_FAILED,
});

actions.getTermsAndConditionUrlRequestSent = () => ({
  type: Constants.GET_TERMS_AND_CONDITION_URL_REQUEST_SENT,
});

actions.getTermsAndConditionUrlRequestSucceeded = (data) => ({
  type: Constants.GET_TERMS_AND_CONDITION_URL_REQUEST_SUCCEEDED,
  payload: {
    data,
  },
});

actions.getTermsAndConditionUrlRequestFailed = () => ({
  type: Constants.GET_TERMS_AND_CONDITION_URL_REQUEST_FAILED,
});

actions.getCurrentUserInfoRequestSent = () => ({
  type: Constants.CURRENT_USER_INFO_REQUEST_SENT,
});

actions.getCurrentUserInfoRequestSucceeded = (userAttributes) => ({
  type: Constants.CURRENT_USER_INFO_REQUEST_SUCCEEDED,
  payload: {
    userAttributes,
  },
});

actions.getCurrentUserInfoRequestFailed = () => ({
  type: Constants.CURRENT_USER_INFO_REQUEST_FAILED,
});

actions.signInRequestSent = () => ({
  type: Constants.SIGNIN_REQUEST_SENT,
});

actions.signInRequestSucceeded = (userAttributes) => ({
  type: Constants.SIGNIN_REQUEST_SUCCEEDED,
  payload: {
    userAttributes,
  },
});

actions.signInRequestFailed = () => ({
  type: Constants.SIGNIN_REQUEST_FAILED,
});

actions.signOutRequestSent = () => ({
  type: Constants.SIGNOUT_REQUEST_SENT,
});

actions.signOutRequestSucceeded = () => ({
  type: Constants.SIGNOUT_REQUEST_SUCCEEDED,
});

actions.signOutRequestFailed = () => ({
  type: Constants.SIGNOUT_REQUEST_FAILED,
});

actions.setHasVerificationBeenAttempted = (hasVerificationBeenAttempted) => ({
  type: Constants.SET_HAS_VERIFICATION_BEEN_ATTEMPTED,
  payload: {
    hasVerificationBeenAttempted,
  },
});

actions.getProfileRequestSent = () => ({
  type: Constants.GET_USER_INFO_REQUEST_SENT,
});

actions.getProfileRequestSucceeded = (data) => ({
  type: Constants.GET_USER_INFO_REQUEST_SUCCEED,
  data,
});

actions.getProfileRequestFailed = () => ({
  type: Constants.GET_USER_INFO_REQUEST_FAILED,
});


actions.updateUserProfileRequestSent = () => ({
  type: Constants.UPDATE_USER_INFO_REQUEST_SENT,
});

actions.updateUserProfileRequestSucceeded = (data) => ({
  type: Constants.UPDATE_USER_INFO_REQUEST_SUCCEED,
  data,
});

actions.updateUserProfileRequestFailed = (data) => ({
  type: Constants.UPDATE_USER_INFO_REQUEST_FAILED,
  data,
});

actions.updateUserProfilePictureRequestSent = () => ({
  type: Constants.UPDATE_USER_PICTURE_REQUEST_SENT,
});

actions.updateUserProfilePictureRequestSucceeded = (data) => ({
  type: Constants.UPDATE_USER_PICTURE_REQUEST_SUCCEED,
  data,
});

actions.updateUserProfilePictureRequestFailed = (data) => ({
  type: Constants.UPDATE_USER_PICTURE_REQUEST_FAILED,
  data,
});

actions.updateUserEmailRequestSent = () => ({
  type: Constants.UPDATE_USER_EMAIL_REQUEST_SENT,
});

actions.updateUserEmailRequestSucceeded = (data) => ({
  type: Constants.UPDATE_USER_EMAIL_REQUEST_SUCCEED,
  data,
});

actions.updateUserEmailRequestFailed = () => ({
  type: Constants.UPDATE_USER_EMAIL_REQUEST_FAILED,
});

actions.updateUserAttributes = (params) => ({
  type: Constants.UPDATE_USER_ATTRIBUTE,
  payload: params,
});

actions.deleteAccountRequestSent = () => ({
  type: Constants.DELETE_ACCOUNT_REQUEST_SENT,
});

actions.deleteAccountRequestSucceeded = () => ({
  type: Constants.DELETE_ACCOUNT_REQUEST_SUCCEED,
});

actions.deleteAccountRequestFailed = () => ({
  type: Constants.DELETE_ACCOUNT_REQUEST_FAILED,
});

export default actions;
