import React from 'react';

import Modal from 'react-bootstrap/lib/Modal';
import './preloginFooter.scss';
import { PrimaryModal } from 'components/StyleGuideComponents/common/modals';

class PreLoginFooter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showContactviewModal: false,
    };
  }

  onContactUsClick = () => {
    this.setState({
      showContactviewModal: true,
    });
  }

  hideContactviewModal = () => {
    this.setState({
      showContactviewModal: false,
    });
  }

  render() {
    return (
      <div className="col-xs-12 pre-login-footer-container">
        <div className="contact-us cursor-pointer" onClick={this.onContactUsClick}>Contact us</div>
        <div className="copy-right-container">
          <div className="copy-right-label">&copy; Higgs Boson Inc, All Rights Reserved</div>
        </div>

        <PrimaryModal
          show={this.state.showContactviewModal}
          onHide={this.hideContactviewModal}
          bodyHeader={{
            text: "Contact Us"
          }}
          bodyContent={{
            text: <div className="footer-content-container">
              <div className="text">Feel free to contact us via email or phone number.</div>
              <div className="text">We would be happy to address your queries.</div>
              <div className="phone-container">
                <i className="icon icon-font-a-contact"></i>
                <span className="phone">(866) 70 - HIGGS</span>
              </div>
              <div className="email-container">
                <i className="icon icon-font-a-email"></i>
                <span className="email">info@higgsbosonhealth.com</span>
              </div>
            </div>
          }}
        />
      </div>
    );
  }
}

export default PreLoginFooter;
