import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import injectReducer from 'utils/injectReducer';
import LightboxModal from 'components/LightboxModal';
import { removeItemFromStorage, getItemFromStorage } from 'services/storage';
import isEmpty from 'lodash/isEmpty';
import Emptyview from 'components/Emptyview';
import NoPatients from 'assets/images/no_patients.png';
import { getPatientsForCaregiverRequest, startCaregiverMasqueradingRequest, clearMasqueradingFlagsRequest } from '../actions';
import caregiverReducer from '../reducers';
import SelectPatientCard from '../SelectPatientCard';

import './selectPatient.scss';


export class SelectPatient extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  componentDidMount() {
    // if (!isEmpty(getItemFromStorage('proxyUser'))) {
    //   this.props.history.replace('/select-procedure');
    // } else {
    removeItemFromStorage('activeCompositeProcedureId');
    // don't auto select patient is there is only one patient when shown in modal/switch patient view
    this.props.getPatientsForCaregiver(!this.props.isShownModaly);
    // }
  }

  componentWillUnmount() {
    this.props.clearMasqueradingFlags();
  }

  onPatientSelect = (patientId) => {
    this.props.startCaregiverMasquerading(patientId);
  }

  render() {
    const proxyUser = isEmpty(getItemFromStorage('proxyUser')) ? null : JSON.parse(getItemFromStorage('proxyUser'));

    return (
      <div className={`select-patient-container ${this.props.isShownModaly ? 'modaly' : ''}`}>
        {
          this.props.patients && this.props.patients.length > 0 ? (
            <div>
              {
                !this.props.isShownModaly && (
                  <div>
                    <Media query={{ maxWidth: 767 }}>
                      {
                        (screenIsSmall) => screenIsSmall ? (
                          <div>
                            <div className="lbl-select-patient-mobile">Select Patient</div>
                          </div>
                        ) : (
                          <div>
                            <div className="lbl-select-patient">Select Patient</div>
                            <div className="select-patient-note">Please select a Patient to continue, you can change it later</div>
                          </div>
                        )}
                    </Media>
                  </div>
                )}
              <div className={`patient-container ${this.props.isShownModaly ? 'modaly' : ''}`}>
                {
                  this.props.patients.map((patient) => (
                    <SelectPatientCard
                      item={patient.info}
                      key={patient.info.id}
                      onPatientSelect={this.onPatientSelect}
                      currentlyViewing={proxyUser && proxyUser.id === patient.info.id}
                      isShownModaly={this.props.isShownModaly}
                    />
                  ))
                }
              </div>
            </div>
          ) : <Emptyview imgSrc={NoPatients} className="" message="There are no patients assigned to you." />
        }
      </div>
    );
  }
}

SelectPatient.propTypes = {
  patients: PropTypes.array,
  getPatientsForCaregiver: PropTypes.func,
  startCaregiverMasquerading: PropTypes.func,
  clearMasqueradingFlags: PropTypes.func,
  // isMasqueradingSuccessful: PropTypes.bool,
  isLoading: PropTypes.bool,
  // masqueradingPatient: PropTypes.object,
  // history: PropTypes.object,
  isShownModaly: PropTypes.bool,
};

SelectPatient.defaultProps = {
  isShownModaly: false,
};


const mapStateToProps = (state) => ({
  isLoading: state.caregiver.isLoading,
  patients: state.caregiver.patients,
  // isMasqueradingSuccessful: state.caregiver.isMasqueradingSuccessful,
  masqueradingPatient: state.caregiver.masqueradingPatient,
});

const mapDispatchToProps = (dispatch) => ({
  getPatientsForCaregiver: (shouldAutoSelectPatient) => dispatch(getPatientsForCaregiverRequest(shouldAutoSelectPatient)),
  startCaregiverMasquerading: (userId) => dispatch(startCaregiverMasqueradingRequest(userId)),
  clearMasqueradingFlags: () => dispatch(clearMasqueradingFlagsRequest()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'caregiver',
  reducer: caregiverReducer,
});

export default compose(
  withRouter,
  withReducer,
  withConnect,
)(SelectPatient);
