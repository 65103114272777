/**
*
* NavigationBar
*
*/

import React from 'react';
import {
  Navbar, Nav, NavDropdown, MenuItem, NavItem,
} from 'react-bootstrap/lib';
import { Link, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getItemFromStorage } from 'services/storage';
import { compose, combineReducers } from 'redux';
import isEmpty from 'lodash/isEmpty';
import Media from 'react-media';

import injectReducer from 'utils/injectReducer';
import NavNotificationMenu from 'containers/NavNotificationMenu';
import { defaultLandingPageRouteGenerator } from 'common/authGuard';
import { getDepartmentProvidersReducer } from 'common/reducer';
import Lookup from 'components/Lookup';
import DDMenuItem from 'components/DropDownMenuItem';
import SwitchPatient from 'patientApp/Caregiver/SwitchPatient';
import {
  USER_TYPE_PATIENT, USER_TYPE_PATIENT_ADVOCATE, USER_TYPE_CAREGIVER, USER_TYPE_PROVIDER,
} from '../../constants';
import './navigation.scss';

import Logo from '../../assets/images/mms.png';
import DefaultPatientAdvocateAvatarImage from '../../assets/images/default_patient_advocate.png';
import DefaultProviderAvatarImage from '../../assets/images/default_provider.png';
import DefaultPatientAvatarImage from '../../assets/images/default_patient.png';
import { navBarPerformerChangeAction, resetPerformerFilter } from './actions';
import { navBarSelectedPerformerReducer } from './reducer';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';

class NavigationBar extends React.Component {
  onSignOut = () => {
    if (this.props.navBar && this.props.selectedPerformer) {
      this.props.resetPerformer();
    }
  }

  onPerformerClick = (e) => {
    let performer = null;
    if (e.target.dataset.id !== '') {
      performer = this.props.performers.find((p) => p.id === parseInt(e.target.dataset.id, 10));
    }
    this.props.updateSelectedPerformer(performer);
  }

  getUserProfileName = (userType) => {
    switch (userType) {
      case USER_TYPE_PROVIDER:
        return 'Provider';
      case USER_TYPE_PATIENT_ADVOCATE:
        return this.props.userProfileName;
      case USER_TYPE_PATIENT:
        return 'Patient';
      case USER_TYPE_CAREGIVER:
        return 'Caregiver';
      default:
        return '';
    }
  }

  redirect = () => defaultLandingPageRouteGenerator()

  renderNavNotificationMenu = () => {
    const proxyUser = isEmpty(getItemFromStorage('proxyUser')) ? null : JSON.parse(getItemFromStorage('proxyUser'));
    const currentUser = isEmpty(getItemFromStorage('currentUser')) ? null : JSON.parse(getItemFromStorage('currentUser'));
    if (!proxyUser && currentUser && currentUser.type === USER_TYPE_CAREGIVER) {
      return null;
    }
    return (<NavNotificationMenu history={this.props.history} />);
  }

  render() {
    const { userDetails } = this.props;
    const currentUserName = `${this.props.userDetails.firstName} ${this.props.userDetails.lastName}`;
    const avatarImage = this.props.userDetails.type === USER_TYPE_PATIENT_ADVOCATE ? DefaultPatientAdvocateAvatarImage : this.props.userDetails.type === USER_TYPE_PROVIDER ? DefaultProviderAvatarImage : DefaultPatientAvatarImage;
    const profilePhoto = this.props.userDetails.profilePhotoUrl || avatarImage;
    const userProfileName = this.getUserProfileName(userDetails.type);
    const isPasswordChangeNeeded = !!((userDetails !== null && this.props.userDetails.needsPasswordChange));
    const userType = userDetails.hasSuperUserPrivileges ? `Super ${userProfileName}` : userDetails.isSupportUser ? `Support ${userProfileName}` : userProfileName;
    const isViewDataDownloadRequestComponent = this.props.location.pathname.includes('view-data-download-request');

    return (
      <div>
        <Navbar fluid className="custom-nav-bar">
          <Navbar.Header>
            <Navbar.Brand>
              <Link
                to={isViewDataDownloadRequestComponent ? this.props.location.pathname : ''}
                replace
                onClick={this.redirect}
              >
                <img src={Logo} className="mms-logo" alt="CareConvoy" />
              </Link>

            </Navbar.Brand>
          </Navbar.Header>
          {
            (this.props.isSignedIn && !isViewDataDownloadRequestComponent) && (
              <Nav pullRight className="custom-dropdown">
                <Media query={{ maxWidth: 767 }}>
                  {
                    (matches) => matches ?
                      (
                        <React.Fragment>
                          {
                            !isPasswordChangeNeeded ? (
                              <LinkContainer to="/profile" style={{ float: 'right', marginRight: '5px' }}>
                                <NavItem>
                                  <span className="icon icon-font-a-profile primary-text-color" aria-hidden="true"></span>
                                </NavItem>
                              </LinkContainer>
                            ) :
                              (
                                <NavItem style={{ float: 'right', marginTop: '5px' }} onClick={this.onSignOut}>
                                  <span className="primary-text-color">Log Out</span>
                                </NavItem>
                              )
                          }
                        </React.Fragment>
                      ) :
                      (
                        <NavDropdown
                          id="basic-nav-dropdown"
                          eventKey={1}
                          title={
                            <span className="icon icon-font-a-profile primary-text-color" aria-hidden="true"></span>
                          }
                        >
                          {
                            !isPasswordChangeNeeded && (
                              <React.Fragment>
                                <div className="user-info-container clearfix">
                                  <div className="col-xs-4 user-image">
                                    <img src={profilePhoto} alt={currentUserName} />
                                  </div>
                                  <div className="col-xs-8 no-padding user-information">
                                    <p className="user-name">{currentUserName}</p>
                                    <p className="user-email">{this.props.userDetails.email}</p>
                                    <p className="user-type">{userType}</p>
                                    <DDMenuItem to="/profile">
                                      <PrimaryButton>
                                        Edit Profile
                                      </PrimaryButton>
                                    </DDMenuItem>
                                  </div>
                                </div>
                                <MenuItem divider />
                                <DDMenuItem to="/change-password" eventKey={1.3} className="menu-dropdown-link">
                                  Change Password
                                </DDMenuItem>
                              </React.Fragment>
                            )
                          }

                          <DDMenuItem
                            to="/logout"
                            toBeReplaced
                            eventKey={1.2}
                            onClick={this.onSignOut}
                            className="menu-dropdown-link"
                          >
                            Log Out
                          </DDMenuItem>
                        </NavDropdown>
                      )
                  }
                </Media>
                {
                  !isPasswordChangeNeeded && this.renderNavNotificationMenu()
                }
                <NavItem style={{ float: 'right' }}>
                  <SwitchPatient />
                </NavItem>
                {
                  !isPasswordChangeNeeded &&
                  ((userDetails.hasSuperUserPrivileges || userDetails.isSupportUser) && this.props.history.location.pathname === '/') && (
                    <NavDropdown
                      className="nav-dropdown-scrollable"
                      id="basic-nav-dropdown"
                      eventKey={2}
                      title={`View As: ${this.props.selectedPerformer === null ? 'All' : `${this.props.selectedPerformer.firstName} ${this.props.selectedPerformer.lastName}`}`}
                    >
                      <MenuItem className="menu-dropdown-link" onClick={this.onPerformerClick} data-id="">All</MenuItem>
                      {
                        this.props.performers.map((performer) => (
                          <MenuItem key={`performer_key_${performer.id}`} className="menu-dropdown-link" onClick={this.onPerformerClick} data-id={performer.id}>
                            {performer.firstName}
                            &nbsp;
                            {performer.lastName}
                          </MenuItem>
                        ))
                      }
                    </NavDropdown>
                  )}
                {
                  (
                    this.props.isSignedIn &&
                    !this.props.isLookup && (this.props.userType !== USER_TYPE_PATIENT && this.props.userType !== USER_TYPE_CAREGIVER)
                    && !isPasswordChangeNeeded
                  ) && (
                    <Lookup
                      history={this.props.history}
                      isSignedIn={this.props.isSignedIn}
                      isLookup={!this.props.isLookup}
                      userType={this.props.userType}
                    />
                  )}
              </Nav>
            )}
        </Navbar>
      </div>
    );
  }
}

NavigationBar.propTypes = {
  userDetails: PropTypes.object,
  userType: PropTypes.any,
  history: PropTypes.object,
  selectedPerformer: PropTypes.object,
  performers: PropTypes.any,
  updateSelectedPerformer: PropTypes.func,
  isSignedIn: PropTypes.bool,
  isLookup: PropTypes.bool,
  navBar: PropTypes.object,
  resetPerformer: PropTypes.func,
  userProfileName: PropTypes.string,
  location: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  userDetails: isEmpty(JSON.parse(getItemFromStorage('currentUser'))) ? state.currentUser.attributes : JSON.parse(getItemFromStorage('currentUser')),
  userType: state.currentUser.attributes.type || (getItemFromStorage('access-token') !== null && getItemFromStorage('currentUser') ? JSON.parse(getItemFromStorage('currentUser')).type : null),
  performers: state.navBar.pro.providers,
  selectedPerformer: state.navBar.per.performer,
  navBar: state.navBar,
  isSignedIn: state.currentUser.isSignedIn || !isEmpty(getItemFromStorage('access-token')),
  isLookup: ownProps.history.location.pathname.indexOf('/lookup/') > -1,
  userProfileName: state.currentUser.attributes.userProfileName,
});

const mapDispatchToProps = (dispatch) => ({
  updateSelectedPerformer: (performer) => dispatch(navBarPerformerChangeAction(performer)),
  resetPerformer: () => dispatch(resetPerformerFilter()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'navBar',
  reducer: combineReducers({
    pro: getDepartmentProvidersReducer,
    per: navBarSelectedPerformerReducer,
  }),
});

export default withRouter(compose(withReducer, withConnect,)(NavigationBar));
