/**
*
* UserId
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import './user_id.scss';


class UserId extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.pad = this.pad.bind(this);
  }

  pad = (str, max) => {
    str = str.toString();
    return str.length < max ? this.pad(`0${str}`, max) : str;
  }


  render() {
    if (this.props.mrn && this.props.mrn !== '') {
      return (
        <div className="user-mrn-container">
          <div className="mrn-label">MRN</div>
          <div className="mrn-value">{this.props.mrn}</div>
        </div>
      );
    }

    return (
      <div className="user-id-container">
        <div className="id-label">ID</div>
        <div className="id-value">{this.pad(this.props.id, 5)}</div>
      </div>
    );
  }
}

UserId.propTypes = {
  id: PropTypes.number,
  mrn: PropTypes.string,
};

export default UserId;
