import { getBannersToShow, acknowledgeBannerViewed } from 'services/connect';

import { showToaster } from 'common/toasterActions';
import * as Constants from './constants';


export const getBannersRequestSent = () => ({
  type: Constants.GET_BANNERS_REQUEST_SENT,
});

export const getBannersRequestSucceeded = (data) => ({
  type: Constants.GET_BANNERS_REQUEST_SUCCEED,
  data: {banners:data.banners},
});

export const getBannersRequestFailed = () => ({
  type: Constants.GET_BANNERS_REQUEST_FAILED,
});

export const acknowledgeBannerViewedRequestSent = () => ({
  type: Constants.ACKNOWLEDGE_BANNER_VIEWED_SENT,
});


export const acknowledgeBannerViewedRequestFailed = () => ({
  type: Constants.ACKNOWLEDGE_BANNER_VIEWED_FAILED,
});


export const updateBanners = () => ({
  type: Constants.UPDATE_BANNERS,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getBannersRequest = (params) => (dispatch) => {
  dispatch(getBannersRequestSent());
  getBannersToShow(params)
    .then((response) => {
      dispatch(getBannersRequestSucceeded((response.data)));
    })
    .catch(() => {
      dispatch(getBannersRequestFailed());
    });
};


export const acknowledgeBannerViewedRequest = (params) => (dispatch) => {
  dispatch(acknowledgeBannerViewedRequestSent());
  acknowledgeBannerViewed(params)
  .catch((response) => {
    dispatch(acknowledgeBannerViewedRequestFailed());
  })
}
