import { PrimaryButton, TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import React from 'react';
import { components } from 'react-select';

const Menu = ({ children, ...props }) => (
  <components.Menu {...props}>
    {children}
    <footer>
      <TertiaryButton
        className="hidden-xs hidden-sm hidden-md"
        onClick={() => props.clearValue()}
        onTouchEnd={() => props.clearValue()}
        onMouseDown={() => props.clearValue()}
      >
        Clear
      </TertiaryButton>

      <PrimaryButton
        onClick={() => handleApplyButton(props.selectProps)}
        onTouchEnd={() => handleApplyButton(props.selectProps)}
        onMouseDown={() => handleApplyButton(props.selectProps)}
      >
        Apply
      </PrimaryButton>

    </footer>
  </components.Menu>
);

const handleApplyButton = (selectedProps) => {
  selectedProps.onApply(selectedProps.value, selectedProps.facet);
};

export default Menu;
