import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import 'assets/stylesheets/mms_common.scss'
import 'components/StyleGuideComponents/styles/modalStyle.scss'
import { LoaderModal } from './common/modals';

export default function CustomModal(props) {
  return (
    <div className="modal-box" >
      <Modal
        show={props.show}
        onHide={props.onHide}
        className={`${props.className}`}
        autoFocus
        aria-labelledby="contained-modal-title"
        backdrop="static"
        style={{ ...props.modalStyle }}
      >
        {
          props.bodyHeader ? (
            <Modal.Header closeButton={props.closeIcon} style={{ display: props.loaderModal ? 'none' : 'block' }}>
              {props.bodyHeader ? <Modal.Title id="contained-modal-title">{props.bodyHeader.text}</Modal.Title> : <></>}
            </Modal.Header>) : <></>
        }
        <Modal.Body>
          {props.bodyContent.text}
        </Modal.Body>
        <Modal.Footer>
          <div style={props.buttonsDirection === 'column' ? { display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", rowGap: "8px" } : { display: "flex", justifyContent: "flex-end", columnGap: "8px" }}>
            {
              props.buttons ? props.buttons.map((button) => button) : null
            }
          </div>
        </Modal.Footer>
      </Modal >
    </div >
  );
}
