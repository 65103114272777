import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getActiveCompositeProcedureId } from 'services/storage';
import Media from 'react-media';
import WatchClickOutside from '../../components/WatchClickOutside';
import { getPatientProcedureRequest, switchActiveProcedureRequest } from '../../common/actions';
import './manageProcedureSection.scss';

class ManageProcedureSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showOptions: false,
    };
  }

  componentDidMount() {
    this.props.fetchPatientProcedures(true);
  }

  onProcedureChange = (event) => {
    const compositeProcedureId = parseInt(event.target.dataset.id, 10);
    if (compositeProcedureId !== this.props.compositeProcedureId) {
      this.props.putSwitchActiveProcedure(compositeProcedureId);
    }
    this.setState({
      showOptions: false,
    });
  }

  onManageProceduresClicked = () => {
    this.props.history.push('/manageprocedures');
  }

  onShowOptions = () => {
    this.setState({
      showOptions: !this.state.showOptions,
    });
  }

  render() {
    const activeCompositeProcedureDetails = this.props.compositeProcedures.find((item) => item.id === this.props.compositeProcedureId);

    return (
      <div className="section-container" ref="manageProcedureSection">

        <Media query={{ maxWidth: 767 }}>
          {
            (screenIsSmall) => screenIsSmall
              ? null :
              (
                <div className="col-sm-3 hidden-xs no-padding lbl-currently-viewing">Currently Viewing: </div>
              )
          }
        </Media>
        <div className="col-sm-5 col-xs-10 select-procedure-dropdown po-rel">
          {activeCompositeProcedureDetails && <div className="col-xs-12 no-padding custom-select" onClick={this.onShowOptions}>{activeCompositeProcedureDetails.name}</div>}
          {
            this.state.showOptions && (
              <WatchClickOutside onClickOutside={this.onShowOptions}>
                <div className="col-xs-12 no-padding custom-select-options">
                  {
                    this.props.compositeProcedures.map((item, index) => (
                      <div key={index} className="single-option" onClick={this.onProcedureChange} data-id={item.id}>
                        <div key={item.id} className="proc-name" data-id={item.id}>{item.name}</div>
                        <div key={index} className="proc-date" data-id={item.id}>{item.compositeProcedureDateInString}</div>
                        {item.id === this.props.compositeProcedureId && <i className="icon icon-font-a-tick" data-id={item.id}></i>}
                      </div>
                    ))
                  }
                </div>
              </WatchClickOutside>
            )}
        </div>
        <Media query={{ maxWidth: 767 }}>
          {
            (screenIsSmall) => screenIsSmall
              ? (
                <div className="col-xs-2 primary-text-color" onClick={this.onManageProceduresClicked}>
                  <span className="icon icon-font-a-manage-procedure" />
                </div>
              ) : (
                <div className="col-sm-4 hidden-xs manage-my-surgery-container cursor-pointer" onClick={this.onManageProceduresClicked}>
                  <span className="icon icon-font-a-manage-procedure"></span>
                  <span className="lbl-manage-my-surgery cursor-pointer">
                    Manage Procedures
                  </span>
                </div>
              )
          }
        </Media>
        <div className="clearfix"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.profile.userDetails,
  isLoading: state.activeProcedureDetails.isLoading,
  isRequestInProgress: state.patientProcedures.isLoading,
  compositeProcedures: state.patientProcedures.compositeProcedures,
  compositeProcedureId: state.activeProcedureDetails.compositeProcedureId || parseInt(getActiveCompositeProcedureId(), 10),
});

const mapDispatchToProps = (dispatch) => ({
  fetchPatientProcedures: (shouldRedirect) => dispatch(getPatientProcedureRequest(shouldRedirect)),
  putSwitchActiveProcedure: (userProcedureId) => dispatch(switchActiveProcedureRequest(userProcedureId)),
});

ManageProcedureSection.propTypes = {
  fetchPatientProcedures: PropTypes.func.isRequired,
  putSwitchActiveProcedure: PropTypes.func.isRequired,
  compositeProcedures: PropTypes.array,
  compositeProcedureId: PropTypes.number.isRequired,
  history: PropTypes.any,
};


export default connect(mapStateToProps, mapDispatchToProps)(ManageProcedureSection);
