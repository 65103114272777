export const REGISTRATION_REQUEST_SENT = 'src/auth/REGISTRATION_REQUEST_SENT';
export const REGISTRATION_REQUEST_SUCCEEDED = 'src/auth/REGISTRATION_REQUEST_SUCCEEDED';
export const REGISTRATION_REQUEST_FAILED = 'src/auth/REGISTRATION_REQUEST_FAILED';

export const GET_TERMS_AND_CONDITION_URL_REQUEST_SENT = 'src/Common/GET_TERMS_AND_CONDITION_URL_REQUEST_SENT';
export const GET_TERMS_AND_CONDITION_URL_REQUEST_SUCCEEDED = 'src/Common/GET_TERMS_AND_CONDITION_URL_REQUEST_SUCCEEDED';
export const GET_TERMS_AND_CONDITION_URL_REQUEST_FAILED = 'src/Common/GET_TERMS_AND_CONDITION_URL_REQUEST_FAILED';

export const CURRENT_USER_INFO_REQUEST_SENT = 'src/auth/CURRENT_USER_INFO_REQUEST_SENT';
export const CURRENT_USER_INFO_REQUEST_SUCCEEDED = 'src/auth/CURRENT_USER_INFO_REQUEST_SUCCEEDED';
export const CURRENT_USER_INFO_REQUEST_FAILED = 'src/auth/CURRENT_USER_INFO_REQUEST_FAILED';

export const SIGNIN_REQUEST_SENT = 'src/auth/SIGNIN_REQUEST_SENT';
export const SIGNIN_REQUEST_SUCCEEDED = 'src/auth/SIGNIN_REQUEST_SUCCEEDED';
export const SIGNIN_REQUEST_FAILED = 'src/auth/SIGNIN_REQUEST_FAILED';

export const SIGNOUT_REQUEST_SENT = 'src/auth/SIGNOUT_REQUEST_SENT';
export const SIGNOUT_REQUEST_SUCCEEDED = 'src/auth/SIGNOUT_REQUEST_SUCCEEDED';
export const SIGNOUT_REQUEST_FAILED = 'src/auth/SIGNOUT_REQUEST_FAILED';

export const SET_HAS_VERIFICATION_BEEN_ATTEMPTED = 'src/auth/SET_HAS_VERIFICATION_BEEN_ATTEMPTED';

export const GET_USER_INFO_REQUEST_SENT = 'src/Common/GET_USER_INFO_REQUEST_SENT';
export const GET_USER_INFO_REQUEST_SUCCEED = 'src/Common/GET_USER_INFO_REQUEST_SUCCEED';
export const GET_USER_INFO_REQUEST_FAILED = 'src/Common/GET_USER_INFO_REQUEST_FAILED';

export const UPDATE_USER_INFO_REQUEST_SENT = 'src/Common/UPDATE_USER_INFO_REQUEST_SENT';
export const UPDATE_USER_INFO_REQUEST_SUCCEED = 'src/Common/UPDATE_USER_INFO_REQUEST_SUCCEED';
export const UPDATE_USER_INFO_REQUEST_FAILED = 'src/Common/UPDATE_USER_INFO_REQUEST_FAILED';

export const UPDATE_USER_PICTURE_REQUEST_SENT = 'src/Common/UPDATE_USER_PICTURE_REQUEST_SENT';
export const UPDATE_USER_PICTURE_REQUEST_SUCCEED = 'src/Common/UPDATE_USER_PICTURE_REQUEST_SUCCEED';
export const UPDATE_USER_PICTURE_REQUEST_FAILED = 'src/Common/UPDATE_USER_PICTURE_REQUEST_FAILED';

export const UPDATE_USER_EMAIL_REQUEST_SENT = 'src/Common/UPDATE_USER_EMAIL_REQUEST_SENT';
export const UPDATE_USER_EMAIL_REQUEST_SUCCEED = 'src/Common/UPDATE_USER_EMAIL_REQUEST_SUCCEED';
export const UPDATE_USER_EMAIL_REQUEST_FAILED = 'src/Common/UPDATE_USER_EMAIL_REQUEST_FAILED';

export const UPDATE_USER_ATTRIBUTE = 'src/auth/UPDATE_USER_ATTRIBUTE';

export const DELETE_ACCOUNT_REQUEST_SENT = 'src/Common/DELETE_ACCOUNT_REQUEST_SENT';
export const DELETE_ACCOUNT_REQUEST_SUCCEED = 'src/Common/DELETE_ACCOUNT_REQUEST_SUCCEED';
export const DELETE_ACCOUNT_REQUEST_FAILED = 'src/Common/DELETE_ACCOUNT_REQUEST_FAILED';
