export const GET_BANNERS_REQUEST_SENT = 'src/Dashboard/GET_BANNERS_REQUEST_SENT';

export const GET_BANNERS_REQUEST_SUCCEED = 'src/Dashboard/GET_BANNERS_REQUEST_SUCCEED';

export const GET_BANNERS_REQUEST_FAILED = 'src/Dashboard/GET_BANNERS_REQUEST_FAILED';

export const ACKNOWLEDGE_BANNER_VIEWED_SENT = 'src/Dashboard/ACKNOWLEDGE_BANNER_VIEWED_SENT';

export const ACKNOWLEDGE_BANNER_VIEWED_FAILED = 'src/Dashboard/ACKNOWLEDGE_BANNER_VIEWED_FAILED';

export const UPDATE_BANNERS = 'src/Dashboard/UPDATE_BANNERS';

