/**
*
* NavNotificationMenu
*
*/

import React from 'react';
import NavItem from 'react-bootstrap/lib/NavItem';
import DDMenuItem from 'components/DropDownMenuItem';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import Media from 'react-media';
import { connect } from 'react-redux';
import injectReducer from 'utils/injectReducer';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import LoadingIndicator from 'components/LoadingIndicator';
import EmptyView from 'components/Emptyview';

import { notificationsReducer } from './reducer';
import { getNotifications, getUnreadNotificationsCount } from './actions';
import NoNotifications from '../../assets/images/no_notifications.png';
import NavNotificationCard from './NavNotificationCard';

class NavNotificationMenu extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };
  }

  componentDidMount() {
    if (this.refs && this.refs.navNotificationsBody) {
      this.refs.navNotificationsBody.addEventListener('scroll', this.handleScroll);
    }
    this.refreshInterval = setInterval(this.props.refreshUnreadCount, 30000);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      page: nextProps.page,
    });
  }

  componentWillUnmount() {
    if (this.refs && this.refs.navNotificationsBody) {
      this.refs.navNotificationsBody.removeEventListener('scroll', this.handleScroll);
    }
    clearInterval(this.refreshInterval);
  }

  onSelect = () => {
    this.setState({
      page: 1,
    }, this.queryNotifications);
  }

  handleScroll = () => {
    if (!this.props.isLast && !this.props.isLoading) {
      if (this.refs.navNotificationsBody.scrollTop === (this.refs.navNotificationsBody.scrollHeight - this.refs.navNotificationsBody.offsetHeight)) {
        this.setState({
          page: this.state.page + 1,
        }, this.queryNotifications);
      }
    }
  }

  queryNotifications = () => {
    this.props.fetchNotifications({ page: this.state.page, limit: 10 });
  }

  seeAllClick = () => {
    if (this.props.notifications.length > 0) {
      this.props.history.push('/notifications');
    }
  }


  render() {
    // Hide notification icon when procedure is completed.
    if (this.props.isProcedureCompleted || this.props.isProfileInfoLoading || this.props.isProfileRequestFailed) {
      return null;
    }
    return (
      <Media query={{ maxWidth: 767 }}>
        {
          (matches) => matches ? (
            <LinkContainer to="/notifications" style={{ float: 'right' }}>
              <NavItem eventKey={1}>
                <span className="nav-notification-icon">
                  <span className="icon icon-font-a-notification-unfilled primary-text-color" aria-hidden="true"></span>
                  {
                    this.props.unreadCount > 0 &&
                    <span className="pending-notifications-badge">{this.props.unreadCount}</span>
                  }
                </span>
              </NavItem>
            </LinkContainer>
          ) :
            (
              <NavDropdown
                id="basic-nav-dropdown"
                className="notification-navbar"
                eventKey={2}
                onClick={this.onSelect}
                noCaret
                title={(
                  <span className="nav-notification-icon">
                    <span className="icon icon-font-a-notification-unfilled primary-text-color" aria-hidden="true"></span>
                    {
                      this.props.unreadCount > 0 &&
                      <span className="pending-notifications-badge">{this.props.unreadCount}</span>
                    }
                  </span>
                )}
              >
                <div className="nav-notifications-container clearfix">
                  <div className="nav-dropdown-header">
                    <div className="nav-dropdown-title col-xs-6">Notifications</div>
                    <div className="col-xs-6 text-right">
                      <DDMenuItem eventKey={2.1} to="/notifications">
                        <span className={`see-all-link ${this.props.notifications.length === 0 ? 'disabled' : ''}`}>See all</span>
                      </DDMenuItem>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="nav-dropdown-body" ref="navNotificationsBody">
                    {
                      this.props.notifications.length === 0 && (
                        <div className="nav-notifications-empty-container">
                          <EmptyView imgSrc={NoNotifications} className="" message="No Notifications" />
                        </div>
                      )
                    }
                    {
                      this.props.notifications.map((notification, index) => (
                        <NavNotificationCard
                          key={index}
                          notification={notification}
                        />))
                    }
                    {
                      this.props.isLoading && (
                        <div>
                          <LoadingIndicator />
                        </div>
                      )
                    }
                  </div>
                </div>
              </NavDropdown>
            )
        }
      </Media>


    );
  }
}

NavNotificationMenu.propTypes = {
  isLoading: PropTypes.bool,
  notifications: PropTypes.array,
  refreshUnreadCount: PropTypes.func,
  page: PropTypes.any,
  isLast: PropTypes.any,
  fetchNotifications: PropTypes.func,
  history: PropTypes.object,
  unreadCount: PropTypes.number,
  isProcedureCompleted: PropTypes.bool,
  isProfileInfoLoading: PropTypes.bool,
  isProfileRequestFailed: PropTypes.bool,
};


const mapStateToProps = (state) => ({
  isLoading: state.notificationsDropdown.isLoading,
  notifications: state.notificationsDropdown.notifications,
  unreadCount: state.notificationsDropdown.unreadCount,
  total: state.notificationsDropdown.total,
  page: state.notificationsDropdown.page,
  isLast: state.notificationsDropdown.isLast,
  isProcedureCompleted: state.profile.userDetails && state.profile.userDetails.info ? state.profile.userDetails.info.isProcedureCompleted : false,
  isProfileInfoLoading: state.profile.isRequestInProgress,
  isProfileRequestFailed: state.profile.isRequestFailed,
});

const mapDispatchToProps = (dispatch) => ({
  fetchNotifications: (params) => dispatch(getNotifications(params)),
  refreshUnreadCount: () => dispatch(getUnreadNotificationsCount()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'notificationsDropdown',
  reducer: notificationsReducer,
});

export default compose(
  withRouter,
  withReducer,
  withConnect,
)(NavNotificationMenu);
