/*
 *
 * PatientDetailsPage reducer
 *
 */
import { isTBD } from 'utils/dateUtils';
import {
  GET_PATIENT_DETAILS_REQUEST_SENT,
  GET_PATIENT_DETAILS_REQUEST_SUCCEED,
  GET_PATIENT_DETAILS_REQUEST_FAILED,
  REMOVE_PATIENT_REQUEST_SENT,
  REMOVE_PATIENT_REQUEST_SUCCEED,
  REMOVE_PATIENT_REQUEST_FAILED,
  UPDATE_NEEDS_ATTENTION_REQUEST_SENT,
  UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED,
  UPDATE_NEEDS_ATTENTION_REQUEST_FAILED,
  SET_PHASE_ID,
  RESEND_INVITE_REQUEST_SENT,
  RESEND_INVITE_REQUEST_SUCCEED,
  RESEND_INVITE_REQUEST_FAILED,
  RESET_PATIENT_DETAILS,
  UPDATE_PATIENT_INFO_REQUEST_SENT,
  UPDATE_PATIENT_INFO_REQUEST_SUCCEED,
  UPDATE_PATIENT_INFO_REQUEST_FAILED,
  TERMINATE_PROCEDURE_TRIAL_REQUEST_SENT,
  TERMINATE_PROCEDURE_TRIAL_REQUEST_SUCCEED,
  TERMINATE_PROCEDURE_TRIAL_REQUEST_FAILED,
  DELETE_PATIENT_REQUEST_SENT,
  DELETE_PATIENT_REQUEST_SUCCEED,
  DELETE_PATIENT_REQUEST_FAILED,
  TRACK_PATIENT_AS_VIEWED_REQUEST_SENT,
  TRACK_PATIENT_AS_VIEWED_REQUEST_SUCCEED,
  TRACK_PATIENT_AS_VIEWED_REQUEST_FAILED
} from './constants';

import { RESET_GLOBAL_REDUCERS } from '../../constants';

const initialState = {
  isLoading: false,
  patient: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    confirmedAt: '',
    cacheKey: '',
    profilePhotoUrl: null,
    userProcedures: [],
    isCurrentProcedureTBD: false,
    relationshipType: '',
  },
  selectedPhaseId: null,
  phases: [],
  currentPhase: {},
  departmentConfiguration: [],
  hasOutcomeTemplates: false,
  hasSurveys: false,
  userHasOptionalTasks: false,
  isProcTrialReqInProgress: false,
  procedureHasOptionalTasks: false,
};

function patientDetailsReducer(state = initialState, action) {
  let currentProcedure = null;
  switch (action.type) {
    case GET_PATIENT_DETAILS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isCurrentProcedureTBD: false,
      };
    case GET_PATIENT_DETAILS_REQUEST_SENT:
      return {
        ...state,
        selectedPhaseId: null,
        isLoading: true,
        isCurrentProcedureTBD: false,
      };
    case GET_PATIENT_DETAILS_REQUEST_SUCCEED:
      currentProcedure = action.payload.patientInfo.patient.userProcedures.find((item) => item.id === action.payload.userProcedureId);
      return {
        ...state,
        isLoading: false,
        patient: {
          ...action.payload.patientInfo.patient,
        },
        isCurrentProcedureTBD: currentProcedure !== null ? isTBD(currentProcedure.utcDatetime) : false,
        phases: [...action.payload.patientInfo.phases],
        currentPhase: { ...action.payload.patientInfo.currentPhase },
        departmentConfiguration: action.payload.patientInfo.departmentConfiguration,
        canProviderSelectRep: action.payload.patientInfo.canProviderSelectRep,
        hasOutcomeTemplates: action.payload.patientInfo.hasOutcomeTemplates,
        userHasOptionalTasks: action.payload.patientInfo.userHasOptionalTasks,
        hasSurveys: action.payload.patientInfo.hasSubmittedSurveys,
        procedureHasOptionalTasks: action.payload.patientInfo.procedureHasOptionalTasks,
        isMrnEditable: action.payload.patientInfo.isMrnEditable,
      };
    case SET_PHASE_ID:
      return {
        ...state,
        selectedPhaseId: action.phaseId,
      };
    case TERMINATE_PROCEDURE_TRIAL_REQUEST_SENT:
      return {
        ...state,
        isProcTrialReqInProgress: true,
      };
    case TERMINATE_PROCEDURE_TRIAL_REQUEST_SUCCEED:
    case TERMINATE_PROCEDURE_TRIAL_REQUEST_FAILED:
      return {
        ...state,
        isProcTrialReqInProgress: false,
      };
    case RESET_GLOBAL_REDUCERS:
    case RESET_PATIENT_DETAILS:
      return initialState;
    default:
      return state;
  }
}

const removePatientInitState = {
  isDeleting: false,
  hasDeleted: false,
};

export const removePatientReducer = (state = removePatientInitState, action) => {
  switch (action.type) {
    case REMOVE_PATIENT_REQUEST_SENT:
      return {
        ...state,
        isDeleting: true,
        hasDeleted: false,
      };
    case REMOVE_PATIENT_REQUEST_SUCCEED:
      return {
        ...state,
        isDeleting: false,
        hasDeleted: true,
      };
    case REMOVE_PATIENT_REQUEST_FAILED:
      return {
        ...state,
        isDeleting: false,
        hasDeleted: false,
      };
    case RESET_PATIENT_DETAILS:
      return removePatientInitState;
    default:
      return state;
  }
};

const needsAttentionInitState = {
  isUpdating: false,
  needsAttention: undefined,
  hasError: false,
};

export const needsAttentionReducer = (state = needsAttentionInitState, action) => {
  switch (action.type) {
    case UPDATE_NEEDS_ATTENTION_REQUEST_SENT:
      return {
        ...state,
        isUpdating: true,
        hasError: false,
      };
    case UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED:
      return {
        ...state,
        isUpdating: false,
        needsAttention: action.response.data.needsAttention,
      };
    case UPDATE_NEEDS_ATTENTION_REQUEST_FAILED:
      return {
        ...state,
        isUpdating: false,
        hasError: true,
        needsAttention: action.response.data.needs_attention,
      };
    case RESET_PATIENT_DETAILS:
      return needsAttentionInitState;
    default:
      return state;
  }
};

const resendInviteInitState = {
  isSending: false,
  hasError: false,
};

export const resendInviteReducer = (state = resendInviteInitState, action) => {
  switch (action.type) {
    case RESEND_INVITE_REQUEST_SENT:
      return {
        ...state,
        isSending: true,
        hasError: false,
      };
    case RESEND_INVITE_REQUEST_SUCCEED:
      return {
        ...state,
        isSending: false,
      };
    case RESEND_INVITE_REQUEST_FAILED:
      return {
        ...state,
        isSending: false,
        hasError: true,
      };
    default:
      return state;
  }
};

const patientInfoInitState = {
  isUpdating: false,
  infoUpdated: false,
};

export const updatePatientInfoReducer = (state = patientInfoInitState, action) => {
  switch (action.type) {
    case UPDATE_PATIENT_INFO_REQUEST_SENT:
      return {
        ...state,
        isUpdating: true,
        infoUpdated: false,
      };
    case UPDATE_PATIENT_INFO_REQUEST_SUCCEED:
      return {
        ...state,
        isUpdating: false,
        infoUpdated: true,
      };
    case UPDATE_PATIENT_INFO_REQUEST_FAILED:
      return {
        ...state,
        isUpdating: false,
        infoUpdated: false,
      };
    default:
      return state;
  }
};

export const trackPatientAsViewedReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRACK_PATIENT_AS_VIEWED_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case TRACK_PATIENT_AS_VIEWED_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
      };
    case TRACK_PATIENT_AS_VIEWED_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default patientDetailsReducer;

const deleteUserInitState = {
  isRemoving: false,
  hasDeleted: false,
};

export const deleteUserReducer = (state = deleteUserInitState, action) => {
  switch (action.type) {
    case DELETE_PATIENT_REQUEST_SENT:
      return {
        ...state,
        isRemoving: true,
        hasRemoved: false,
      };
    case DELETE_PATIENT_REQUEST_SUCCEED:
      return {
        ...state,
        isRemoving: false,
        hasRemoved: true,
      };
    case DELETE_PATIENT_REQUEST_FAILED:
      return {
        ...state,
        isRemoving: false,
        hasRemoved: false,
      };
    case RESET_PATIENT_DETAILS:
      return deleteUserInitState;
    default:
      return state;
  }
};
