import moment from 'moment-timezone';

export const currentTimezoneName = moment.tz.guess();// Intl.DateTimeFormat().resolvedOptions().timeZone; // Gives "Asia/Calcutta"
moment.tz.setDefault(currentTimezoneName);

export const STANDARD_DATE_FORMAT = 'DD-MM-YYYY';
export const MMS_DATE_FORMAT = 'MMM DD, YYYY';

export const dateStringToMMSDateFormat = (dateString) => moment(dateString, moment.ISO_8601).format('ddd, MMM DD, YYYY');

export const dateStringToMMSDateWithoutDayFormat = (dateString) => moment(dateString, moment.ISO_8601).format('MMM DD, YYYY');

export const dateWithTimeStringToMMSDateFormat = (dateString) => moment(dateString, moment.ISO_8601).format('ddd, MMM DD, hh:mm A');

export const dateTimeStringToMMSDateFormat = (dateString) => moment(dateString, moment.ISO_8601).format('MMM DD, YYYY hh:mm A');

export const dateTimeStringToMMSTimeFormat = (dateString) => moment(dateString, moment.ISO_8601).format('hh:mm A');

export const dateStringToDate = (dateString) => moment(dateString, moment.ISO_8601);

export const dateStringToCustomDateTime = (dateString) => moment(dateString, moment.ISO_8601).format('MMM DD, YYYY [at ] hh:mm A');

export const dateStringToCustomDate = (dateString) => moment(dateString, moment.ISO_8601).format('MMM DD, YYYY');

export const dateStringToCustomTimeWithTimezone = (dateString, timezone) => moment(dateString, moment.ISO_8601).tz(timezone).format('hh:mm A');

export const dateStringToCustomDateWithTimezone = (dateString, timezone) => moment(dateString, moment.ISO_8601).tz(timezone).format('ddd, MMM DD, YYYY');

export const dateStringToDateWithDateFormat = (dateString) => moment(dateString, moment.ISO_8601).format('DD/MM/YYYY');

export const dateStringToDateWithMMDDYYYYFormat = (dateString) => moment(dateString, moment.ISO_8601).format('MM/DD/YYYY');

export const getWeekDetailsOnSelectedDate = (dateString = null) => {
  let selectedDate = moment().isoWeekday(1);
  if (dateString) {
    selectedDate = moment(dateString, moment.ISO_8601).isoWeekday(1);
  }

  const startOfWeek = selectedDate.startOf('isoWeek');
  let day = startOfWeek;
  const currentWeek = [];
  for (let i = 0; i < 7; i += 1) {
    currentWeek.push(day);
    day = day.clone().add(1, 'd');
  }
  return currentWeek;
};

export const isTBD = (dateString) => parseInt(moment(dateString, moment.ISO_8601).format('YYYY'), 10) <= 1970;

export const isDueToday = (dateString, timezone) => moment().tz(timezone).isSame(dateString, 'day');

export const isOverDue = (dateString, timezone) => moment().tz(timezone).isAfter(dateString, 'time');

export const isOverDueByDate = (dateString, timezone) => moment().tz(timezone).isAfter(dateString, 'day');

export const getAppointmentDateString = (appointment) => {
  if (appointment.dateKnown) {
    let timeString = ' Time: TBD';
    if (appointment.timeKnown) {
      timeString = dateTimeStringToMMSTimeFormat(moment(appointment.utcDatetime).tz(appointment.locationTimezone));
    }
    return `${dateStringToMMSDateFormat(moment(appointment.utcDatetime).tz(appointment.locationTimezone))} at ${timeString}`;
  }

  return 'TBD';
};

export const getAppointmentEndDateString = (userProcedure) => {
  if (userProcedure.dateKnown) {
    return userProcedure.procedureEndDate ? `${dateStringToMMSDateFormat(moment(userProcedure.procedureEndDate).tz(userProcedure.locationTimezone))}` : `${dateStringToMMSDateFormat(moment(userProcedure.utcDatetime).add('days', 7).tz(userProcedure.locationTimezone))}`;
  }
  return '';
};

export const isDateExpired = (procedureDate, dateString, timezone) => {
  const d2 = moment().tz(timezone);
  if (dateString) {
    return true;
  }
  const d1 = moment(procedureDate).add('days', 7).tz(timezone);
  return d1.isSameOrBefore(d2, 'day');
};

export const hasProcedureStarted = (procedureDate, timezone) => {
  const d1 = moment().tz(timezone).startOf('day');
  const d2 = moment(procedureDate).tz(timezone).startOf('day');
  return d2.isBefore(d1);
};

export const getDateStringWithoutTimezone = (dateString) => {
  const date = new Date(dateString);
  const dateStr = date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : `${date.getUTCDate()}`;
  const monthStr = (date.getUTCMonth() + 1) < 10 ? `0${date.getUTCMonth() + 1}` : `${date.getUTCMonth() + 1}`;
  return `${date.getUTCFullYear()}-${monthStr}-${dateStr}`;
};
