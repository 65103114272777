/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import authReducer, { userInfoReducer } from 'common/authReducers';
import { toasts } from 'common/toasterReducers';
import patientInfo from 'containers/PatientDetails/reducer';
import patientProceduresReducer, { switchProcedureReducer } from 'patientApp/common/reducers';
import passiveModeReducer from 'patientApp/containers/PassiveMode/reducers';
import patientCardReducer from 'common/patientCardReducer';
import shareOutcomesReportReducer from 'common/shareOutcomesReportReducer';
import {
  getProcedureLocationsReducer, getProcedureProvidersReducer, addNewProcedureReducer, saveSearchFiltersReducer,
} from 'common/reducer';
import { patientSearchReducer } from 'containers/PatientSearch/reducer';
import { bannersReducer } from 'containers/Banners/reducer';
import { LoadingReducer } from 'common/reducer';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    toasters: toasts,
    currentPatientInfo: patientInfo,
    currentUser: authReducer,
    profile: userInfoReducer,
    patientProcedures: patientProceduresReducer,
    activeProcedureDetails: switchProcedureReducer,
    prov: getProcedureProvidersReducer,
    loc: getProcedureLocationsReducer,
    addProcedureRequest: addNewProcedureReducer,
    search: patientSearchReducer,
    customFilter: saveSearchFiltersReducer,
    passiveMode: passiveModeReducer,
    patientCard: patientCardReducer,
    shareOutcomesReport: shareOutcomesReportReducer,
    bannersReducer,
    LoadingReducer,
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  return rootReducer;
}
