/* eslint-disable no-console */
import isEmpty from 'lodash/isEmpty';
import Cookies from 'universal-cookie';
import { authHeaderKeys, proxyAuthHeaderKeys } from './constants';
import envConstants from 'envConstants';

const isTestingMode = envConstants.NODE_ENV === 'testing';
const defaultStorage = isTestingMode ? new Cookies() : window.sessionStorage; // Default session storage

const defaultPathForCookieStorage = '/';

export const getAuthHeaders = () => {
  const headers = {};
  authHeaderKeys.forEach((key) => {
    headers[key] = getItemFromStorage(key);
  });
  return headers;
};

export const persistAuthHeadersInDeviceStorage = (headers) => {
  authHeaderKeys.forEach((key) => {
    setItemToStorage(key, headers[key]);
  });
};

export const persistProxyAuthHeadersInDeviceStorage = (headers) => {
  proxyAuthHeaderKeys.forEach((key) => {
    setItemToStorage(key, headers[key]);
  });
};

export const deleteAuthHeadersFromDeviceStorage = () => {
  authHeaderKeys.forEach((key) => {
    removeItemFromStorage(key);
  });
};


const deleteAllCookies = (storage = defaultStorage) => {
  const result = storage.getAll();
  Object.keys(result).forEach((key) => {
    removeItemFromStorage(key, storage);
  });
};

export const setItemToStorage = (key, value, storage = defaultStorage) => {
  if (isTestingMode) {
    if (isEmpty(value)) {
      return storage.set(key, value, { path: defaultPathForCookieStorage });
    }
    return storage.set(key, JSON.stringify(value), { path: defaultPathForCookieStorage });
  }
  return storage.setItem(key, value);
};

export const getItemFromStorage = (key, storage = defaultStorage) => isTestingMode ? storage.get(key) : storage.getItem(key);

export const getDefaultStorage = () => defaultStorage;


export const clearDefaultStorage = () => isTestingMode ? deleteAllCookies() : defaultStorage.clear();

export const removeItemFromStorage = (key, storage = defaultStorage) => isTestingMode ? storage.remove(key) : storage.removeItem(key);

export const getCurrentUser = () => {
  if (!getAuthenticationStatus()) {
    return null;
  }
  try {
    const user = getItemFromStorage('currentUser');
    return user ? JSON.parse(user) : null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const isLoggedIn = () => !isEmpty(getItemFromStorage('access-token'));

export const getAuthenticationStatus = () => !isEmpty(getItemFromStorage('access-token'));

export const getActiveCompositeProcedureId = () => {
  const compositeProcedureId = getItemFromStorage('activeCompositeProcedureId');
  try {
    if (compositeProcedureId !== null && !Number.isNaN(compositeProcedureId)) {
      return parseInt(compositeProcedureId, 10);
    }
  } catch (error) {
    console.log('getActiveCompositeProcedureId', error);
  }
  return 0;
};
