import * as Constants from './constants';

const initialState = {
  isLoading: false,
  banners: []
};

export const bannersReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.GET_BANNERS_REQUEST_SENT:
    case Constants.ACKNOWLEDGE_BANNER_VIEWED_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_BANNERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.GET_BANNERS_REQUEST_SUCCEED:
    case Constants.ACKNOWLEDGE_BANNER_VIEWED_FAILED:
      return {
        ...state,
        banners: action.data.banners,
      };
    case Constants.UPDATE_BANNERS:
      return {
        ...state,
        banners: state.banners.slice(1)
      }
    default:
      return state;
  }
};

