import React from 'react';
import Modal from 'components/StyleGuideComponents/Modal';
import variables from '../../../assets/stylesheets/_variables.scss';


const loaderModalStyle = {
  background: variables.lightBoxModalBackgroundColor,
  // background: "transparent",
  boxShadow: "none",
}

export const PrimaryModal = (props) => <Modal closeIcon {...props} />;
export const SecondaryModal = (props) => <Modal closeIcon className="secondary-modal" {...props} />;
export const TertiaryModal = (props) => <Modal closeIcon {...props} buttonsDirection="column" />;
export const LoaderModal = (props) => <Modal {...props} loaderModal modalStyle={loaderModalStyle} />