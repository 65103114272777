import { showToaster } from 'common/toasterActions';
import {
  fetchAllUsersOfTheDepartment,
  addNewProvider,
  updateProviderInfo,
  fetchAllPerformersOfUserTypeMaster,
  fetchAllLocations,
} from "services/userAdministration";

import {
  GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SENT,
  GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SUCCEED,
  GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_FAILED,
  ADD_NEW_PROVIDER_REQUEST_SENT,
  ADD_NEW_PROVIDER_REQUEST_SUCCEED,
  ADD_NEW_PROVIDER_REQUEST_FAILED,
  UPDATE_PROVIDER_INFO_REQUEST_SENT,
  UPDATE_PROVIDER_INFO_REQUEST_SUCCEED,
  UPDATE_PROVIDER_INFO_REQUEST_FAILED,
  GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SENT,
  GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SUCCEED,
  GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_FAILED,
  GET_ALL_LOCATIONS_REQUEST_SENT,
  GET_ALL_LOCATIONS_REQUEST_SUCCEED,
  GET_ALL_LOCATIONS_REQUEST_FAILED,
} from "./constants";

export const fetchAllUsersOfTheDepartmentRequestSent = () => ({
  type: GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SENT
});

export const fetchAllUsersOfTheDepartmentSucceeded = data => ({
  type: GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SUCCEED,
  data
});

export const fetchAllUsersOfTheDepartmentRequestFailed = data => ({
  type: GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_FAILED,
  data
});

export const addNewProviderRequestSent = () => ({
  type: ADD_NEW_PROVIDER_REQUEST_SENT,
});

export const addNewProviderSucceeded = () => ({
  type: ADD_NEW_PROVIDER_REQUEST_SUCCEED,
});

export const addNewProviderRequestFailed = () => ({
  type: ADD_NEW_PROVIDER_REQUEST_FAILED,
});

export const updateProviderInfoRequestSent = () => ({
  type: UPDATE_PROVIDER_INFO_REQUEST_SENT
});

export const updateProviderInfoSucceeded = () => ({
  type: UPDATE_PROVIDER_INFO_REQUEST_SUCCEED
});

export const updateProviderInfoRequestFailed = () => ({
  type: UPDATE_PROVIDER_INFO_REQUEST_FAILED
});

export const fetchAllPerformersOfUserTypeMasterRequestSent = () => ({
  type: GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SENT,
});

export const fetchAllPerformersOfUserTypeMasterSucceeded = (data) => ({
  type: GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SUCCEED,
  data,
});

export const fetchAllPerformersOfUserTypeMasterRequestFailed = (data) => ({
  type: GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_FAILED,
  data,
});

export const fetchAllLocationsRequestSent = () => ({
  type: GET_ALL_LOCATIONS_REQUEST_SENT,
});

export const fetchAllLocationsSucceeded = (data) => ({
  type: GET_ALL_LOCATIONS_REQUEST_SUCCEED,
  data,
});

export const fetchAllLocationsRequestFailed = (data) => ({
  type: GET_ALL_LOCATIONS_REQUEST_FAILED,
  data,
});

export const onfetchAllUsersOfTheDepartmentRequest = (params) => (dispatch) => {
  dispatch(fetchAllUsersOfTheDepartmentRequestSent());
  fetchAllUsersOfTheDepartment(params)
    .then((response) => {
      dispatch(fetchAllUsersOfTheDepartmentSucceeded(response.data));
    })
    .catch((error) => {
      dispatch(fetchAllUsersOfTheDepartmentRequestFailed(error.response.data));
    });
};

export const onAddNewProviderRequest = (params) => (dispatch) => {
  dispatch(addNewProviderRequestSent());
  addNewProvider(params)
    .then(() => {
      dispatch(addNewProviderSucceeded());
      dispatch(showToaster({ message: "Performer added successfully." }));
      dispatch(onfetchAllUsersOfTheDepartmentRequest(params.pagination));
    })
    .catch(() => {
      dispatch(addNewProviderRequestFailed());
    });
};

export const updateProviderInfoAction = (params) => (dispatch) => {
  dispatch(updateProviderInfoRequestSent());
  updateProviderInfo(params)
    .then(() => {
      dispatch(updateProviderInfoSucceeded());
      dispatch(showToaster({ message: 'Performer details updated successfully.' }));
      dispatch(onfetchAllUsersOfTheDepartmentRequest(params.pagination));
    })
    .catch(() => {
      dispatch(updateProviderInfoRequestFailed());
    });
};

export const onfetchPerformersOfUserTypeMasterRequest = () => (dispatch) => {
  dispatch(fetchAllPerformersOfUserTypeMasterRequestSent());
  fetchAllPerformersOfUserTypeMaster()
    .then((response) => {
      dispatch(fetchAllPerformersOfUserTypeMasterSucceeded(response.data));
    })
    .catch((error) => {
      dispatch(fetchAllPerformersOfUserTypeMasterRequestFailed(error.response.data));
    });
};

export const onfetchLocationsRequest = () => (dispatch) => {
  dispatch(fetchAllLocationsRequestSent());
  fetchAllLocations()
    .then((response) => {
      dispatch(fetchAllLocationsSucceeded(response.data));
    })
    .catch((error) => {
      dispatch(fetchAllLocationsRequestFailed(error.response.data));
    });
};