import axios from './base';
import { POST_CREATE_NOTE, PUT_UPDATE_NOTE, PUT_UPDATE_COMMENTS, GET_BANNERS, ACKNOWLEDGE_BANNER_VIEWED } from './constants';

export const getPatientNotes = (patientId, userProcedureId) => axios.get(`connect_patients/${patientId}/user_procedures/${userProcedureId}/notes`);

export const getPatientNoteDetails = (noteId) => axios.get(`connect/notes/${noteId}`);

export const postComments = (noteId, params) => axios.post(`connect/notes/${noteId}/comments`, params);

export const postCreateNotes = (params) => axios.post(POST_CREATE_NOTE, params);

export const editCommunicationLogNotes = (noteId, params) => axios.put(`${PUT_UPDATE_NOTE}${noteId}`, params);

export const editCommunicationLogComments = (commentId, params) => axios.put(`${PUT_UPDATE_COMMENTS}${commentId}`, params);

export const getBannersToShow = () => axios.get(GET_BANNERS);

export const acknowledgeBannerViewed = (params) => axios.post(ACKNOWLEDGE_BANNER_VIEWED, params)
