import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, combineReducers } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import debounce from 'lodash/debounce';
import injectReducer from 'utils/injectReducer';
import BackNavigation from 'patientApp/components/BackNavigation';
import QuestionSearchResultCard from 'patientApp/components/QuestionSearchResultCard';
import MasqueradingHeader from 'patientApp/Caregiver/MasqueradingHeader';
import isEmpty from 'lodash/isEmpty';
import LoadingIndicator from 'components/LoadingIndicator';
import { getActiveCompositeProcedureId } from 'services/storage';
import { getAllQuestionFaqsRequest } from '../FaqQuestionsComponent/actions';
import procedureReducer, { questionSearchReducer } from '../reducers';
import { searchQuestionsRequest, clearSearchResultsRequest } from '../actions';
import Strings from '../../../../strings';
import { SEARCHABLE_QUESTION_QUERY_LENGTH } from '../../../../constants';

import './styles.scss';

class QuestionSearch extends Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.searchQuery = debounce(this.doSearch, 400);
    this.state = {
      isAskingQuestionAllowed: props.location.state ? props.location.state.isAskingQuestionAllowed : false,
      questionText: '',
      queryText: '',
      lastPage: false,
      isTextCleared: false,
      showNoResults: false,
    };
  }

  componentDidMount = () => {
    const compositeProcedureId = getActiveCompositeProcedureId();
    if (compositeProcedureId) {
      this.props.fetchAllFaqQuestions(compositeProcedureId);
    }
    this.refs.searchResultsContainer.addEventListener('scroll', this.handleScroll, true);
    const urlParams = queryString.parse(this.props.location.search);
    const searchQuery = urlParams.query;
    if (searchQuery && !isEmpty(searchQuery)) {
      const params = this.getSearchAPIParams(searchQuery, '*');
      this.doSearch(params, false);
      this.setState({ questionText: searchQuery, queryText: searchQuery });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lastPage !== this.props.lastPage) {
      this.setState({ lastPage: nextProps.lastPage });
    }
    if (nextProps.showNoResults !== this.state.showNoResults) {
      this.setState({ showNoResults: nextProps.showNoResults });
    }
  }

  componentWillUnmount() {
    this.props.clearResults();
    this.refs.searchResultsContainer.removeEventListener('scroll', this.handleScroll);
  }

  onClearText = () => {
    this.setState({ questionText: '', isTextCleared: true });
    this.props.clearResults();
    this.props.history.replace('/search/questions', { isAskingQuestionAllowed: this.state.isAskingQuestionAllowed });
  }

  onCreateQuestionClicked = () => {
    const compositeProcedureId = getActiveCompositeProcedureId();
    this.props.history.push(`/questions/${compositeProcedureId}/ask-question`, { procedures: this.props.procedures });
  }

  getSearchAPIParams(searchQuery, cursor) {
    return {
      query: searchQuery,
      cursor,
    };
  }

  doSearch = (params, isScrolled) => {
    this.props.history.replace(`/search/questions?query=${params.query}`, { isAskingQuestionAllowed: this.state.isAskingQuestionAllowed });
    this.props.searchQuery(params, isScrolled);
  }

  handleQuestionTextChange = (e) => {
    const text = e.target.value;
    this.setState({
      questionText: text, lastPage: false, isTextCleared: false, showNoResults: false,
    }, () => {
      if (text.trim().length >= SEARCHABLE_QUESTION_QUERY_LENGTH) {
        this.triggerSearchQuery();
      }
    });
    // this will take care user deleting characters to clear results when total search text is less than or equal to 3 characters
    if (text.length < SEARCHABLE_QUESTION_QUERY_LENGTH) {
      this.props.clearResults();
    }
  };

  handleScroll = () => {
    if (this.refs.searchResultsContainer.scrollTop === (this.refs.searchResultsContainer.scrollHeight - this.refs.searchResultsContainer.offsetHeight)) {
      this.triggerSearchQuery(true);
    }
  }

  triggerSearchQuery = (isScrollEvent = false) => {
    if (this.state.questionText.length >= SEARCHABLE_QUESTION_QUERY_LENGTH && !this.props.isLoading && !this.state.lastPage) {
      const params = this.getSearchAPIParams(this.state.questionText, this.state.queryText !== this.state.questionText ? '*' : this.props.nextPageCursor ? this.props.nextPageCursor : '*');
      this.setState({
        queryText: this.state.questionText,
      }, this.searchQuery(params, isScrollEvent));
    }
  }

  render() {
    return (
      <div className="question-search-container">
        <MasqueradingHeader />
        <BackNavigation title="Search questions" />
        <div className="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 search-main-container">
          <div className="search-input">
            <input
              id="search"
              type="search"
              className="search-box"
              placeholder="Search"
              value={this.state.questionText}
              onChange={this.handleQuestionTextChange}
            />
            {
              this.state.questionText && this.state.questionText !== '' &&
              <span role="button" tabIndex="0" className="icon icon-font-a-close app-grey clear-icon" onClick={this.onClearText} />
            }
          </div>
          <div ref="searchResultsContainer" className="search-results">
            {!this.state.isTextCleared && this.state.questionText.length >= SEARCHABLE_QUESTION_QUERY_LENGTH && (
              <React.Fragment>
                {
                  this.props.results && this.props.results.length > 0 ?
                    (
                      <React.Fragment>
                        <div className="lbl-search-results">Search Results </div>
                        {
                          this.props.results.map((result) => (
                            <QuestionSearchResultCard key={result.id} item={result} />
                          ))
                        }
                      </React.Fragment>
                    ) : (
                      this.state.showNoResults ? (
                        <div className="no-results">
                          <div className="header">{Strings.NO_RESULTS_FOUND_HEADER.replace('%searchTerm', this.state.queryText)}</div>
                          <div className="subtext">{this.state.isAskingQuestionAllowed ? Strings.NO_RESULTS_SUBTEXT1.replace('#', '1.') : Strings.NO_RESULTS_SUBTEXT1.replace('#', '')}</div>
                          {this.state.isAskingQuestionAllowed && (
                            <div>
                              <div className="subtext">{Strings.NO_RESULTS_SUBTEXT2.replace('#', '2.')}</div>
                              <div className="create-question">
                                <div role="button" tabIndex="0" className="create-question-btn float-right" onClick={this.onCreateQuestionClicked}>
                                  <span className="icon icon-font-a-add-caregiver"></span>
                                  <span className="btn-text">Create new Question</span>
                                </div>
                                <div className="clearfix" />
                              </div>
                            </div>
                          )}
                        </div>
                      ) : null
                    )
                }
                {
                  this.props.isLoading && (
                    <div className="col-xs-12 loading-indicator"><LoadingIndicator /></div>
                  )
                }
                {
                  this.props.lastPage && this.props.total !== 0 && !this.state.showNoResults && !this.props.isLoading &&
                  <div className="end-of-search text-align-center">End of Results</div>
                }
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}


QuestionSearch.propTypes = {
  isLoading: PropTypes.bool,
  searchQuery: PropTypes.func,
  clearResults: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  results: PropTypes.array,
  showNoResults: PropTypes.bool,
  lastPage: PropTypes.bool,
  nextPageCursor: PropTypes.string,
  total: PropTypes.number,
  fetchAllFaqQuestions: PropTypes.func,
  procedures: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isLoading: state.questionSearch.search.isLoading,
  showNoResults: state.questionSearch.search.showNoResults,
  results: state.questionSearch.search.questions,
  total: state.questionSearch.search.total,
  lastPage: state.questionSearch.search.lastPage,
  firstPage: state.questionSearch.search.firstPage,
  totalPages: state.questionSearch.search.totalPages,
  currentCursor: state.questionSearch.search.currentCursor,
  nextPageCursor: state.questionSearch.search.nextPageCursor,
  showNoResultsView: state.questionSearch.search.questions && state.questionSearch.search.questions.length === 0,
  procedures: state.questionSearch.procedureDetails.procedures,
});

const mapDispatchToProps = (dispatch) => ({
  searchQuery: (params, isScrolled) => dispatch(searchQuestionsRequest(params, isScrolled)),
  clearResults: () => dispatch(clearSearchResultsRequest()),
  fetchAllFaqQuestions: (compositeProcedureId) => dispatch(getAllQuestionFaqsRequest(compositeProcedureId)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'questionSearch',
  reducer: combineReducers({
    search: questionSearchReducer,
    procedureDetails: procedureReducer,
  }),
});

export default compose(
  withReducer,
  withRouter,
  withConnect
)(QuestionSearch);
