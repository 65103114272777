export default {
  API_URL: process.env.REACT_APP_API_URL || 'http://localhost:3000',
  NODE_ENV: process.env.REACT_APP_NODE_ENV,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_AUTH_TOKEN: process.env.REACT_APP_SENTRY_AUTH_TOKEN,
  SENTRY_PROJECT: process.env.REACT_APP_SENTRY_PROJECT,
  SENTRY_ORG: process.env.REACT_APP_SENTRY_ORG,
  SHOW_OUTCOME_CHARTS: process.env.REACT_APP_SHOW_OUTCOME_CHARTS,
  MAP_KEY: process.env.REACT_APP_MAP_KEY,
  DISABLE_AGGREGATE_OUTCOMES: process.env.REACT_APP_DISABLE_AGGREGATE_OUTCOMES,
  PORT: (process.env.REACT_APP_PORT || '8080', 10),
  HOST: process.env.REACT_APP_HOST,
};
