import React from "react"
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import startCase from "lodash/startCase";
import { withRouter } from "react-router-dom";
import injectReducer from "utils/injectReducer";
import {
  onfetchAllUsersOfTheDepartmentRequest,
  onAddNewProviderRequest,
  updateProviderInfoAction,
  onfetchPerformersOfUserTypeMasterRequest,
  onfetchLocationsRequest,
} from "./actions";
import { userAdministrationReducer } from "./reducer";
import {
  SingleSelectWithoutBorder as CustomizedSelect,
  CustomMultiSelect,
} from "components/SelectV2";
import RadioButton from "components/RadioButton";
import LightboxModal from 'components/LightboxModal';
import HelpBlock from 'components/HelpBlock';
import { Modal, ModalBody } from 'react-bootstrap/lib';
import validate, { clearErrorsForField } from 'common/validator';
import validationConfig from './validator';
import './user_administration.scss';
import Pagination from 'components/Pagination';
import { getItemFromStorage } from 'services/storage';

import InputMask from 'react-input-mask';
import Tab from 'react-bootstrap/lib/Tab';
import Nav from 'react-bootstrap/lib/Nav';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import NavItem from 'react-bootstrap/lib/NavItem';
import Emptyview from 'components/Emptyview';
import NoProviders from '../../assets/images/default_provider.png';
import { PrimaryButton, SecondaryButton } from "components/StyleGuideComponents/common/buttons";
import { PrimaryModal } from "components/StyleGuideComponents/common/modals";

const userTypeOptions = [
  { value: "master", label: "Provider/Provider", type: "Provider" },
  { value: "support", label: "Provider/Clinical user", type: "Provider" },
  { value: "master", label: "Representative/Provider", type: "PatientAdvocate" },
  { value: "support", label: "Representative/Clinical user", type: "PatientAdvocate" },
];

const userTypeOptions1 = [
  { value: "master", label: "Provider/Provider", type: "Provider" },
  { value: "support", label: "Provider/Clinical user", type: "Provider" },
];

class UserAdministration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddEditUserInfoModal: false,
      userId: null,
      userFirstName: '',
      userLastName: '',
      userEmail: '',
      ehrIdentifier: '',
      netId: '',
      providerType: '',
      userType: '',
      userIsActive: true,
      emailDisabled: false,
      userTypeDisabled: false,
      users: [],
      primaryPhysicians: [],
      locations: [],
      errors: {},
      canAddOnlyProviders: false,
      page: 1,
      hasSuperUserPrivileges: false,
      showTicketIdField: false,
      ticketId: '',
      isInputFocused: false,
      filterNameOrEmail: "",
      filterLocationIds: [],
      previousFilterData: {
        previousPage: 1,
        previousFilterNameOrEmail: '',
        previousFilterLocationIds: []
      }
    };
  }

  componentDidMount = () => {
    const currentUser = JSON.parse(getItemFromStorage('currentUser'));
    if (!(currentUser.hasSuperUserPrivileges && currentUser.isAdmin)) {
      this.props.history.push('/not-found');
    }
    else {
      window.addEventListener("scroll", this.scrollEvent);
      this.props.fetchAllUsersOfTheDepartment({ 'page': this.state.page });
      this.props.fetchLocations();
      this.props.fetchPerformersOfUserTypeMaster();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isRequestInProgress === false && prevProps.isRequestInProgress === true) {
      this.setState({
        showAddEditUserInfoModal: this.props.show
      })
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.state.canAddOnlyProviders !== nextProps.canAddOnlyProviders) {
      this.setState({
        canAddOnlyProviders: nextProps.canAddOnlyProviders,
        providerType: 'Provider',
      })
    }
  }

  scrollEvent = () => {
    let tableHeader = document.getElementById("main-header");
    let fixedHeader = document.getElementById("fixed-header");

    if (tableHeader != null && fixedHeader != null) {
      tableHeader = document.getElementById("main-header").getBoundingClientRect();
      fixedHeader = document.getElementById("fixed-header").getBoundingClientRect();

      if (tableHeader.top <= fixedHeader.top) {
        document.getElementById("fixed-header").style.visibility = 'visible';
      }
      else {
        document.getElementById("fixed-header").style.visibility = 'hidden';
      }
    }
  }

  onAddEditUserInfoModal = (user) => {
    const {
      id,
      firstName,
      lastName,
      email,
      netId,
      ehrIdentifier,
      type,
      userType,
      hasSuperUserPrivileges,
      isActive
    } = user || {};

    const selectedPrimaryPhysicians = this.getSelectedPrimaryPhysicians(id);
    const selectedLocations = this.getSelectedLocations(id)

    this.setState({
      userId: id ? id : null,
      userFirstName: firstName ? firstName : "",
      userLastName: lastName ? lastName : "",
      userEmail: email ? email : "",
      ehrIdentifier: ehrIdentifier ? ehrIdentifier : "",
      netId: netId ? netId : "",
      providerType: type ? type : this.state.canAddOnlyProviders ? 'Provider' : "",
      userType: userType && type ? userTypeOptions.find((item) => item.value === userType && item.type === type) : "",
      showAddEditUserInfoModal: true,
      primaryPhysicians: selectedPrimaryPhysicians ? selectedPrimaryPhysicians : [],
      locations: selectedLocations ? selectedLocations : [],
      emailDisabled: email ? true : false,
      userTypeDisabled: userType ? true : false,
      hasSuperUserPrivileges: hasSuperUserPrivileges ? hasSuperUserPrivileges : false,
      showTicketIdField: false,
      ticketId: '',
      userIsActive: isActive
    });
  }

  onAddNewLocation = () => {
    this.props.history.push('/locations');
  }

  handlePagination = (page) => {
    this.setState({ page })
    this.props.fetchAllUsersOfTheDepartment({ 'page': page });
  }

  getSelectedPrimaryPhysicians = (id) => {
    const associatedPrimaryPhysiciansIds = this.props.primaryPhysicians.filter((item) => item.supportUserId === id);

    const associatedPrimaryPhysicians = [];
    associatedPrimaryPhysiciansIds.forEach((el) => {
      const primaryPhysiciansId = this.props.performersOfUserTypeMaster.find((item) => item.id === el.userId);
      primaryPhysiciansId && associatedPrimaryPhysicians.push(primaryPhysiciansId);
    })

    const selectedItems = associatedPrimaryPhysicians.map((item) => ({
      value: item.id,
      label: `${item.firstName} ${item.lastName}`,
    }));
    return selectedItems;
  }

  getSelectedLocations = (id) => {
    const associatedProviderLocationsIds = this.props.providerLocations.filter((item) => item.providerId === id);

    const associatedLocations = [];
    associatedProviderLocationsIds.forEach((item) => {
      associatedLocations.push(this.props.locations.find((l) => l.id === item.locationId));
    });

    const selectedItems = associatedLocations.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    return selectedItems;
  }

  changeFieldHandler = (e) => {
    const obj = {};
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, e.target.name);
    this.setState(obj);
  };

  selectUserType = (selectedOption) => {
    this.setState({
      userType: selectedOption,
      providerType: selectedOption.type,
      primaryPhysicians: selectedOption != this.state.userType ? [] : this.state.primaryPhysicians,
      locations: selectedOption.value === 'master' ? this.state.locations : [],
      errors: clearErrorsForField(this.state.errors, 'userType'),
      errors: clearErrorsForField(this.state.errors, 'providerType')
    });
  }

  selectPrimaryPhysicians = (selectedValues) => {
    this.setState({ primaryPhysicians: selectedValues, errors: clearErrorsForField(this.state.errors, 'primaryPhysicians') });
  }

  selectLocations = (selectedValues) => {
    this.setState({ locations: selectedValues });
  }

  onAddProviderInfo = () => {
    this.validateAddEditProviderFormValues()
  }

  onEditProviderInfo = () => {
    this.validateAddEditProviderFormValues()
  }

  validateAddEditProviderFormValues = () => {
    if (this.state.showTicketIdField && !(validationConfig.fields.includes("ticketId"))) {
      validationConfig.fields.push("ticketId");
      validationConfig.rules.ticketId = [
        { rule: 'isRequired', message: "AAT Jira ticket id is required to create or update a user to have super user privileges." }
      ];
    } else if (!this.state.showTicketIdField && validationConfig.fields.includes("ticketId")) {
      validationConfig.fields = validationConfig.fields.filter((item) => item !== 'ticketId');
      delete validationConfig.rules.ticketId;
    }

    validate(validationConfig, this.state, this.onFormValidationFailure, this.onFormValidationSuccess);
  }

  onFormValidationFailure = (errors) => {
    this.setState({ errors });
  }

  onFormValidationSuccess = () => {
    const { primaryPhysicians, locations } = this.state;

    const params = {
      first_name: this.state.userFirstName,
      last_name: this.state.userLastName,
      email: this.state.userEmail,
      ehr_identifier: this.state.ehrIdentifier ? this.state.ehrIdentifier : null,
      net_id: this.state.netId ? this.state.netId : null,
      provider_type: this.state.providerType,
      user_type: this.state.userType.value,
      pagination: { page: this.state.page },
      has_super_user_privileges: this.state.hasSuperUserPrivileges,
      ticket_id: this.state.ticketId,
      user_is_active: this.state.userIsActive
    };

    if (primaryPhysicians) {
      params.performer_ids = primaryPhysicians.map((item) => item.value)
    }

    if (locations) {
      params.location_ids = locations.map((item) => item.value)
    }

    if (this.state.userId) {
      params.id = this.state.userId;

      this.props.updateProviderInfo(
        params,
      );
    } else {
      this.props.addNewProvider(
        params,
      );
    }
  }

  hideAddEditUserInfoModal = () => {
    this.setState({
      showAddEditUserInfoModal: false,
      errors: {},
    });
  };

  addOrEditSuperUserStatus = () => {
    this.setState(previousState => ({
      hasSuperUserPrivileges: !previousState.hasSuperUserPrivileges,
      showTicketIdField: !previousState.showTicketIdField,
      ticketId: '',
      errors: clearErrorsForField(this.state.errors, 'ticketId')
    }))
  }

  changeUserAccountStatus = () => {
    this.setState(previousState => ({
      userIsActive: !previousState.userIsActive
    }));

  }

  changeFilterNameOrEmail = (e) => {
    this.setState({ filterNameOrEmail: e.target.value });
  }

  onChangeFilterLocation = (selectedValues) => {
    this.setState({ filterLocationIds: selectedValues });
  }

  isFilterUpdated = (currentData, previousData) => {
    return (currentData.page !== previousData.previousPage) ||
      (currentData.filterNameOrEmail.trim().toUpperCase() !== previousData.previousFilterNameOrEmail.trim().toUpperCase()) ||
      (JSON.stringify(currentData.filterLocationIds.sort()) !== JSON.stringify(previousData.previousFilterLocationIds.sort()))
  }

  extractIdsFromObjects = (objects) => objects.map((item) => item.value);

  onSetFilter = (isPagination) => {
    const {
      filterNameOrEmail,
      filterLocationIds
    } = this.state;

    const page = isPagination ? this.state.page : 1;

    let currentData = { filterNameOrEmail, filterLocationIds, 'page': this.state.page }
    if (this.isFilterUpdated(currentData, this.state.previousFilterData)) {
      this.props.fetchAllUsersOfTheDepartment({ 'page': page, name: filterNameOrEmail, location_ids: this.extractIdsFromObjects(filterLocationIds) });
      this.setState({
        page: page,
        previousFilterData: {
          previousPage: this.state.page,
          previousFilterNameOrEmail: this.state.filterNameOrEmail,
          previousFilterLocationIds: this.state.filterLocationIds
        }
      });
    }
  }

  onResetFilter = () => {
    this.setState({
      filterNameOrEmail: '',
      filterLocationIds: [],
      page: 1,
      errors: {},
      previousFilterData: {
        previousPage: 1,
        previousFilterNameOrEmail: '',
        previousFilterLocationIds: []
      }
    }, () => {
      this.props.fetchAllUsersOfTheDepartment({ 'page': this.state.page })
    });
  }


  renderFilters = () => {
    return (
      <div className="filters-container">
        <div className="filters-label">
          Filters
        </div>
        <div className="row filter-wrapper">

          <div className="col-xs-12">
            <label htmlFor="filterNameOrEmail">Name/Email</label>
            <input
              type="text"
              className="form-control"
              placeholder="Type Name or Email here"
              onChange={this.changeFilterNameOrEmail}
              value={this.state.filterNameOrEmail}
              name="nameOrEmail"
            />
          </div>
          <div className="col-xs-12">
            <label htmlFor="filterLocation">Location</label>
            <CustomMultiSelect
              allowSelectAll
              isLoading={this.props.isFilterDataRequestInProgress}
              onChange={this.onChangeFilterLocation}
              value={this.state.filterLocationIds}
              isSearchable={false}
              placeholder={this.state.filterLocationIds.length === 0 ? "Select" : "Selected"}
              options={
                this.props.locations.map((item) => ({
                  value: item.id, label: item.name,
                }))
              }
            />
            <HelpBlock value={this.state.errors.locations} />
          </div>
          <div className="col-xs-12 btn-wrapper">
            <SecondaryButton
              className={`clear-all ${this.state.isFilterApplied ? '' : 'cursor-pointer'}`}
              onClick={this.onResetFilter}
            >
              Clear All

            </SecondaryButton>
            <PrimaryButton
              className="apply-button"
              onClick={() => this.onSetFilter(false)}
            >
              Apply Filters
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }

  renderData = () => {
    return (
      <>
        {this.props.users.length > 0 ?
          <div className="row">
            <div className="col-sm-12">
              <div className="ui-table-container">
                <table className="ui-table">
                  <thead className="sticky-header">
                    <tr className=''>
                      <th>Name</th>
                      <th>Email</th>
                      <th>User type</th>
                      <th>Primary Users</th>
                      <th>Locations</th>
                      <th>Account status</th>
                      <th style={{ width: '50px' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.props.users.map((user) => (
                        <tr className='' key={user.id}>
                          <td>{user.firstName} {user.lastName}</td>

                          <td>{user.email}</td>

                          <td>
                            {userTypeOptions.find((item) => item.value === user.userType && item.type === user.type).label}
                          </td>

                          <td>
                            {
                              this.getSelectedPrimaryPhysicians(user.id).length > 0 ? this.getSelectedPrimaryPhysicians(user.id).map((item) => (
                                <p key={item.value}>{item.label}</p>
                              )) : '- -'

                            }
                          </td>
                          <td>
                            {
                              user.locations.length > 0 ? user.locations.map((loc) => (
                                <p key={loc.id}>{loc.name}</p>
                              )) : '- -'
                            }
                          </td>
                          <td>
                            <p className={user.isActive ? "text-success" : "text-danger"}>{user.isActive ? 'Active' : 'Inactive'}</p>
                          </td>
                          <td className="edit-container" style={{ width: '20px' }}>
                            <span className="icon icon-font-a-write-message cursor-pointer" onClick={() => this.onAddEditUserInfoModal(user)} />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <div style={{ marginBottom: '20px', marginTop: '10px' }}>
                {this.props.pagination &&
                  <Pagination pagination={this.props.pagination} title="Providers" handlePagination={this.handlePagination} />
                }
              </div>
            </div>
          </div>
          :
          <Emptyview imgSrc={NoProviders} className="" message="No Users" />
        }
      </>
    )
  }

  renderChildComponents = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-3 col-md-3 col-lg-3 filter-container-wrapper" style={{ position: 'fixed' }}>
            {this.renderFilters()}
          </div>
          <div className="col-sm-9 col-md-9 col-lg-9 record-list-wrapper" style={{ float: 'right' }}>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <span className="add-new-link" onClick={this.onAddEditUserInfoModal}>
                <span className="icon icon-font-a-add-new-task"></span>
                Add New {this.state.canAddOnlyProviders ? 'Provider' : 'User'}
              </span>
              <span className="pagination">
                {this.props.pagination &&
                  <Pagination pagination={this.props.pagination} title="Providers" handlePagination={this.handlePagination} />
                }
              </span>
            </div>
            <div className="clr"></div>
            {this.renderData()}
          </div>
        </div>
      </React.Fragment>)
  }

  render() {
    return (
      <>
        {this.props.manageUsersEnabled &&
          <div className="col-xs-12 content-administration">
            <Tab.Container id="admin-activity-tabs">
              <React.Fragment>
                <div className='admin-navigation-div'>
                  <div className="admin-navigation-section">
                    <Nav bsStyle="pills" justified>
                      <LinkContainer to="/users" replace>
                        <NavItem eventKey="providers">
                          <React.Fragment>
                            <i className="icon icon-font-a-patient-under-physician"></i>
                            <span className="icon-label">{this.state.canAddOnlyProviders ? 'Providers' : 'Users'}</span>
                          </React.Fragment>
                        </NavItem>
                      </LinkContainer>
                      <LinkContainer to="/locations" replace>
                        <NavItem eventKey="locations">
                          <React.Fragment>
                            <i className="icon icon-font-a-location"></i>
                            <span className="icon-label">Locations</span>
                          </React.Fragment>
                        </NavItem>
                      </LinkContainer>
                    </Nav>
                  </div>
                </div>
                <div className="tab-content-wrapper">
                  <Tab.Content>
                    {
                      this.renderChildComponents()
                    }
                  </Tab.Content>
                </div>
              </React.Fragment>
            </Tab.Container>

            <PrimaryModal
              show={this.state.showAddEditUserInfoModal}
              onHide={this.hideAddEditUserInfoModal}
              bodyHeader={{
                text: this.state.userId ? `Edit ${this.state.canAddOnlyProviders ? 'Providers' : 'User'} Info` : `Add New ${this.state.canAddOnlyProviders ? 'Providers' : 'User'}`

              }}
              bodyContent={{
                text: <div id="edit-user-modal">
                  <div className={`form-group ${this.state.errors.userFirstName !== undefined ? 'has-error' : ''}`}>
                    <label htmlFor="">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      name="userFirstName"
                      value={this.state.userFirstName}
                      onChange={this.changeFieldHandler}
                    />
                    <HelpBlock value={this.state.errors.userFirstName} />
                  </div>

                  <div className={`form-group ${this.state.errors.userLastName !== undefined ? 'has-error' : ''}`}>
                    <label htmlFor="">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      name="userLastName"
                      value={this.state.userLastName}
                      onChange={this.changeFieldHandler}
                    />
                    <HelpBlock value={this.state.errors.userLastName} />
                  </div>

                  <div className={`form-group ${this.state.errors.userEmail !== undefined ? 'has-error' : ''}`}>
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="userEmail"
                      // readOnly={this.state.emailDisabled}
                      value={this.state.userEmail}
                      onChange={this.changeFieldHandler}
                    />
                    <HelpBlock value={this.state.errors.userEmail} />
                  </div>

                  <div className={`form-group ${this.state.errors.userType !== undefined ? 'has-error' : ''}`}>
                    <label htmlFor="userType">Select User Type</label>
                    <CustomizedSelect
                      id="select-user-type"
                      placeholder="Select User Type"
                      isDisabled={this.state.userTypeDisabled}
                      onChange={this.selectUserType}
                      value={userTypeOptions.filter((item) => item.value === this.state.userType.value && item.type === this.state.providerType)}
                      options={this.state.canAddOnlyProviders ? userTypeOptions.filter((item) => item.type === 'Provider') : userTypeOptions}
                    />
                    <HelpBlock value={this.state.errors.userType} />
                  </div>
                  {this.props.isEhrSupported &&
                    <div className={`form-group`}>
                      <label htmlFor="">EHR Identifier</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="EHR Identifier"
                        name="ehrIdentifier"
                        value={this.state.ehrIdentifier}
                        onChange={this.changeFieldHandler}
                      />
                    </div>
                  }
                  {this.props.isAddXealthNetIdPermitted &&
                    <div className={`form-group`}>
                      <label htmlFor="">Net Id</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Net Id"
                        name="netId"
                        value={this.state.netId}
                        onChange={this.changeFieldHandler}
                      />
                    </div>
                  }

                  {
                    this.state.userType && this.state.userType.value !== "master" && this.state.providerType ?
                      (
                        <div className={`form-group ${this.state.errors.primaryPhysicians !== undefined ? 'has-error' : ''}`}>
                          <label htmlFor="primaryPhysicians">Primary Users</label>
                          <CustomMultiSelect
                            id="select-primary-physicians"
                            isDisabled={(this.state.userType === '' || this.state.userType.value === 'master') || this.state.providerType === ''}
                            placeholder={this.state.primaryPhysicians.length === 0 ? "Select" : "Selected"}
                            onChange={this.selectPrimaryPhysicians}
                            value={this.state.primaryPhysicians}
                            options={
                              this.props.performersOfUserTypeMaster.filter((item) => item.type === this.state.providerType).map((item) => ({
                                value: item.id, label: `${item.firstName} ${item.lastName}`,
                              }))
                            }
                          />
                          <HelpBlock value={this.state.errors.primaryPhysicians} />
                        </div>
                      ) : (null)
                  }

                  {
                    this.state.userType && this.state.userType.value === "master" && this.state.providerType !== "PatientAdvocate" ?
                      (
                        <div className={`form-group`}>
                          <label htmlFor="locations">Locations</label>
                          <CustomMultiSelect
                            id="select-locations"
                            isDisabled={(this.state.userType === '' || this.state.userType.value === 'support')}
                            onChange={this.selectLocations}
                            value={this.state.locations}
                            placeholder={this.state.locations.length === 0 ? "Select" : "Selected"}
                            options={
                              this.props.locations.map((item) => ({
                                value: item.id, label: `${item.name}`,
                              }))
                            }
                          />
                        </div>
                      ) : (null)
                  }

                  <div className="super-user-toggel-holder">
                    <div className="">Mark as Super User</div>
                    <div className="">
                      <RadioButton
                        onToggle={this.addOrEditSuperUserStatus}
                        value={this.state.hasSuperUserPrivileges}
                        defaultValue={this.state.hasSuperUserPrivileges}
                      />
                    </div>
                  </div>

                  {this.state.showTicketIdField &&
                    <div className={`form-group ${this.state.errors.ticketId !== undefined ? 'has-error' : ''}`}>
                      <label htmlFor="">Ticket Id</label>
                      <InputMask
                        placeholder={'AAT-'}
                        mask={'AAT\-99999'}
                        maskChar={null}
                        name="ticketId"
                        value={`AAT-${this.state.ticketId}`}
                        onChange={this.changeFieldHandler}
                      />
                      <HelpBlock value={this.state.errors.ticketId} />
                    </div>
                  }

                  {this.state.userId ? (
                    <div className="user-is_active-holder">
                      <label htmlFor="">Mark this user as active</label>
                      <RadioButton
                        onToggle={this.changeUserAccountStatus}
                        defaultValue={this.state.userIsActive}
                      />
                      <HelpBlock value={this.state.errors.userFirstName} />
                    </div>) : null
                  }
                </div>
              }}
              buttons={[
                <PrimaryButton
                  onClick={this.state.userId ? this.onEditProviderInfo : this.onAddProviderInfo}
                >
                  Save
                </PrimaryButton>
              ]}
            />
          </div>
        }
      </>
    )
  }
}

UserAdministration.propTypes = {
  users: PropTypes.array,
  show: PropTypes.bool,
  isRequestInProgress: PropTypes.bool,
  isEhrSupported: PropTypes.bool,
  isAddXealthNetIdPermitted: PropTypes.bool,
  isGetAllPerformersRequestInProgress: PropTypes.bool,
  fetchAllUsersOfTheDepartment: PropTypes.func,
  addNewProvider: PropTypes.func,
  updateProviderInfo: PropTypes.func,
  fetchPerformersOfUserTypeMaster: PropTypes.func,
  performersOfUserTypeMaster: PropTypes.array,
  manageUsersEnabled: PropTypes.bool,
  isGetAllLocationsRequestInProgress: PropTypes.bool,
};

const mapStateToProps = state => ({
  users: state.userAdministration.userAdministrationReducer.users,
  primaryPhysicians: state.userAdministration.userAdministrationReducer.primaryPhysicians,
  isRequestInProgress: state.userAdministration.userAdministrationReducer.isRequestInProgress,
  isGetAllPerformersRequestInProgress: state.userAdministration.userAdministrationReducer.isGetAllPerformersRequestInProgress,
  isGetAllLocationsRequestInProgress: state.userAdministration.userAdministrationReducer.isGetAllLocationsRequestInProgress,
  show: state.userAdministration.userAdministrationReducer.show,
  error: state.userAdministration.userAdministrationReducer.error,
  performersOfUserTypeMaster: state.userAdministration.userAdministrationReducer.performersOfUserTypeMaster,
  locations: state.userAdministration.userAdministrationReducer.locations,
  providerLocations: state.userAdministration.userAdministrationReducer.providerLocations,
  canAddOnlyProviders: state.currentUser.attributes.canAddOnlyProviders,
  manageUsersEnabled: state.currentUser.attributes.hasSuperUserPrivileges && state.currentUser.attributes.isAdmin,
  pagination: state.userAdministration.userAdministrationReducer.pagination,
  isEhrSupported: state.currentUser.attributes.isEhrSupported,
  isAddXealthNetIdPermitted: state.currentUser.attributes.isAddXealthNetIdPermitted,
});

const mapDispatchToProps = dispatch => ({
  fetchAllUsersOfTheDepartment: (params) => dispatch(onfetchAllUsersOfTheDepartmentRequest(params)),
  addNewProvider: (params) => dispatch(onAddNewProviderRequest(params)),
  updateProviderInfo: (params) => dispatch(updateProviderInfoAction(params)),
  fetchPerformersOfUserTypeMaster: () => dispatch(onfetchPerformersOfUserTypeMasterRequest()),
  fetchLocations: () => dispatch(onfetchLocationsRequest()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "userAdministration",
  reducer: combineReducers({
    userAdministrationReducer,
  }),
});

export default compose(
  withRouter,
  withReducer,
  withConnect
)(UserAdministration);
