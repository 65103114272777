import React, { useState } from "react";
import Modal from "react-bootstrap/lib/Modal";
import ReactHtmlParser from "react-html-parser";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBannersRequest, acknowledgeBannerViewedRequest, updateBanners } from "./actions";
import { PrimaryButton, SecondaryButton, TertiaryButton } from "components/StyleGuideComponents/common/buttons";
import { PrimaryModal } from "components/StyleGuideComponents/common/modals";

const Banners = (props) => {
  const dispatch = useDispatch();

  const banners = useSelector((store) => store.bannersReducer.banners);

  const [showBannerModal, setShowBannerModal] = useState(false);
  const [currentBanner, setCurrentBanner] = useState(null);
  const isSignedIn = useSelector((store) => store.currentUser.isSignedIn);


  useEffect(() => {
    if (currentBanner)
      setShowBannerModal(true);
  }, [currentBanner]);


  useEffect(() => {
    if (banners.length)
      setCurrentBanner(banners[0]);
  }, [banners])

  useEffect(() => {
    if (isSignedIn) {
      dispatch(getBannersRequest());
    }
  }, [isSignedIn]);


  const updateBannerViewed = (bannerId, buttonId) => {
    dispatch(acknowledgeBannerViewedRequest({ banner_id: bannerId, button_id: buttonId }))
  }

  const getModalButtons = () => {
    let buttonsArr = []
    if ("buttons" in currentBanner?.actionResource) {
      <div>
        {currentBanner?.actionResource.buttons.map((button) => {
          if (button?.btnType !== "cross") {
            if (button?.btnType !== null) {
              return (
                buttonsArr.push(getButtonClass(button))
              )
            }
            return (<></>)
          }
          return <></>;
        })}
      </div>
    }
    return buttonsArr
  }

  const getButtonClass = (button) => {
    switch (button?.btnType) {
      case "primary":
        return <SecondaryButton style={{ width: "140px" }} onClick={() => {
          if (button?.actionType === 'url')
            window.open(button?.actionValue, '_blank')
          onHide(currentBanner.id, button.id)
        }}>{button?.btnText}</SecondaryButton>;
      case "secondary":
        return <PrimaryButton style={{ width: "140px" }} onClick={() => {
          if (button?.actionType === 'url')
            window.open(button?.actionValue, '_blank')
          onHide(currentBanner.id, button.id)
        }}>{button?.btnText}</PrimaryButton>;
      case "tertiary":
        return <TertiaryButton style={{ width: "140px" }} onClick={() => {
          if (button?.actionType === 'url')
            window.open(button?.actionValue, '_blank')
          onHide(currentBanner.id, button.id)
        }}>{button?.btnText}</TertiaryButton>;
      default:
        return <PrimaryButton style={{ width: "140px" }} onClick={() => {
          if (button?.actionType === 'url')
            window.open(button?.actionValue, '_blank')
          onHide(currentBanner.id, button.id)
        }}>{button?.btnText}</PrimaryButton>;
    }
  };

  const onHide = (bannerId, buttonId) => {
    updateBannerViewed(bannerId, buttonId);
    setShowBannerModal(false);
    dispatch(updateBanners())
  }

  if (!currentBanner) return <></>
  return currentBanner ? (
    <PrimaryModal
      show={showBannerModal}
      onHide={() => {
        onHide(currentBanner.id, 0);
      }}
      bodyHeader={{
        text: "title" in currentBanner?.actionResource
          ? ReactHtmlParser(currentBanner?.actionResource?.title)
          : ""
      }}
      bodyContent={{
        text: <div className="delete-custom-filter-modal-body">
          {currentBanner?.actionResource?.imageUrl ? (
            <div>
              <img
                alt="Banner Display"
                src={currentBanner?.actionResource?.imageUrl}
                style={{ width: "100%", maxHeight: "248px", display: "flex", margin: "0 auto" }}
              />
            </div>
          ) : (
            ""
          )}
          {"body" in currentBanner?.actionResource
            ? ReactHtmlParser(currentBanner?.actionResource.body)
            : ""}
        </div>
      }}
      buttons={getModalButtons()}
    />) : (
    <></>)
};

export default Banners;
