import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FlashMessage from 'components/FlashMessage';
import difference from 'lodash/difference';
import { removeToaster } from 'common/toasterActions';

class Toasters extends Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      toasters: [],
    };
    this.onRemoveToast = this.onRemoveToast.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      toasters: difference(nextProps.toasters, this.props.toasters),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const toasters = difference(this.state.toasters, prevState.toasters);
    if (toasters.length) {
      toasters.forEach((item) => {
        setTimeout(() => {
          prevProps.removeToast(item.id);
        }, item.timeout);
      });
    }
  }

  onRemoveToast = (event, toasterId) => {
    event.preventDefault();
    this.props.removeToast(toasterId);
  }

  render() {
    return (
      <React.Fragment>
        {
          this.props.toasters ?
            this.props.toasters.map((toast) => <FlashMessage key={toast.id} {...toast} onRemoveToast={this.onRemoveToast} />) : null
        }
      </React.Fragment>
    );
  }
}

Toasters.propTypes = {
  toasters: PropTypes.array,
  removeToast: PropTypes.func,
};

const mapStateToProps = (state) => ({
  toasters: state.toasters,
});

const mapDispatchToProps = (dispatch) => ({
  removeToast: (id) => dispatch(removeToaster(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasters);
