import { showToaster } from 'common/toasterActions';
import { showShareOutcomesRequest } from 'services/patient/shareOutcomes';
import * as Constants from './constants';

export const verifyPinRequestSent = () => ({
  type: Constants.VERIFY_PIN_REQUEST_SENT,
});

export const verifyPinRequestSucceeded = (data) => ({
  type: Constants.VERIFY_PIN_REQUEST_SUCCEED,
  data,
});

export const verifyPinRequestFailed = (data) => ({
  type: Constants.VERIFY_PIN_REQUEST_FAILED,
  data,
});

export const verifyPinRequest = (requestKey, requestPin) => ((dispatch) => {
  dispatch(verifyPinRequestSent());
  const params = { request_key: requestKey, request_pin: requestPin };
  showShareOutcomesRequest(params)
    .then((response) => {
      dispatch(verifyPinRequestSucceeded(response.data));
    })
    .catch((error) => {
      dispatch(verifyPinRequestFailed(error.response && error.response.data ? error.response.data : null));
      dispatch(showToaster({ message: error.response.data.message, type: 'error' }));
    });
});
