import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SpinnerImage from 'assets/images/spinner.gif';
import './lightbox_modal.scss';
import { LoaderModal } from 'components/StyleGuideComponents/common/modals';

const LightboxModal = (props) => {
  const [loaderStyle, setLoaderStyle] = useState({});
  const [loaderSpin, setLoaderSpin] = useState(null);
  useEffect(() => {
    if (props.show) {
      setLoaderStyle({ display: "block" });
      setLoaderSpin(SpinnerImage);
    }
    else {
      setLoaderStyle({ display: "none" });
      setLoaderSpin(null);
    }
  }, [props.show])

  return (
    <div >
      <LoaderModal
        show={props.show}
        className="lightbox-modal"
        bodyContent={{
          text: <div style={loaderStyle}> <img src={loaderSpin} alt="Loading..." className="spinner-image" /> </div>
        }}
      />
    </div>)
}

LightboxModal.propTypes = {
  show: PropTypes.bool,
};

export default LightboxModal;
